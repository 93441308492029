import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../model/User';
import { UserService } from '../user.service';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.less']
})
export class DeleteUserModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      user: User,
      startLoadingScreen: any,
      stopLoadingScreen: any,
    },
    private userService: UserService,
    public dialogRef: MatDialogRef<DeleteUserModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  async delete() {
    this.data.startLoadingScreen();
    try {
      await this.userService.delete(this.data.user) as unknown as Object[];
    } catch (e) {
      alert('Ocorreu algum erro durante a deleção deste usuário.');
    }
    this.dialogRef.close(true);
    this.data.stopLoadingScreen();
  }

}
