import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from './generic.service';
import { Cor } from './model/Cor';
import { Modelo } from './model/Modelo';
import { Produto } from './model/Produto';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class ModeloService extends GenericService<Modelo> {

  constructor(requestsService: RequestsService, router: Router) {
    super('api/modelo', requestsService, router);
  }

  async saveModelo(modelo: any) {
    return await this.requestsService.put(`${this.url}/${modelo.id}`, modelo).toPromise() as Modelo;
  }

  async tornarCorPadrao(produto: Produto, cor: Cor) {
    return await this.requestsService.post(`${this.url}/tornarCorPadrao`, {
      produto_id: produto.id,
      cor_id: cor.id
    }).toPromise();
  }

  async deleteCor(produto: Produto, cor: Cor) {
    return await this.requestsService.post(`${this.url}/deleteByCor`, {
      produto_id: produto.id,
      cor_id: cor.id
    }).toPromise();
  }

  /*
    Dentro do server, deleta os arquivos temporários e substitui eles pelo arquivo original.
    Serve para limpar o cache de arquivos que foram upados, mas não salvos
  */
  async resetFaces(modelo: Modelo) {
    let data = {
      modelo_id: modelo.id
    }

    return await this.requestsService.post(`api/modelo/resetFaces`, data).toPromise() as Modelo;
  }

  async criarModelos(produto: Produto, cor: Cor) {
    let data = {
      produto_id: produto.id,
      cor_id: cor.id,
    }

    return await this.requestsService.post(`api/modelo/criarParaProduto`, data).toPromise() as Modelo;
  }

}