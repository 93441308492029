import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Face } from '../model/Face';
import { Predefinicao } from '../model/Predefinicao';
import { PredefinicaoService } from '../predefinicao.service';

@Component({
  selector: 'app-save-predefinicao-modal',
  templateUrl: './save-predefinicao-modal.component.html',
  styleUrls: ['./save-predefinicao-modal.component.less']
})
export class SavePredefinicaoModalComponent implements OnInit {

  nome: string = "";

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      selectedFace: Face,
      tamanhoId: number
    },
    private predefinicaoService: PredefinicaoService,
    public dialogRef: MatDialogRef<SavePredefinicaoModalComponent>
  ) {
    
  }

  ngOnInit(): void {

  }

  save() {
    let predefinicao : Predefinicao = {
      nome: this.nome,
      face_id: this.data.selectedFace.mirror_face_id ?? this.data.selectedFace.face_id,
      tamanho_id: this.data.tamanhoId,
      is_default: false,
      valores: {
          x: this.data.selectedFace.x,
          y: this.data.selectedFace.y,
          width: this.data.selectedFace.width,
          height: this.data.selectedFace.height,
          font_family: this.data.selectedFace.font_family,
          font_color: this.data.selectedFace.font_color,
          font_size: this.data.selectedFace.font_size,
          borders: this.data.selectedFace.borders,
      }
    };
    this.predefinicaoService.create(predefinicao);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

}
