import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private requestsService: RequestsService, private http: HttpClient) { }

  async login(email: string, password: string) : Promise<boolean> {
    await this.http.get(`${RequestsService.BASE}sanctum/csrf-cookie`).toPromise() as any;
    var response = await this.requestsService.post("api/login", { email, password }).toPromise() as any;
    localStorage.setItem('session', response.access_token);
    localStorage.setItem('permissions', JSON.stringify(response.permissions));
    return true;
  }

  async logout() {
    localStorage.removeItem('session');
    localStorage.removeItem('permissions');
  }

  static getUserSession() : string {
    return localStorage.getItem('session');
  }

  static getUserPermissions() : any {
    return JSON.parse(localStorage.getItem('permissions'));
  }

}
