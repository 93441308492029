import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from './generic.service';
import { Produto } from './model/Produto';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends GenericService<Produto> {

  constructor(requestsService: RequestsService, router: Router) {
    super('api/produto', requestsService, router);
  }

  async getSelectList() : Promise<any[]> {
    return await this.requestsService.get(`${this.url}/selectList`).toPromise() as unknown as Object[];
  }

  async duplicate(produto: Produto, novoCodigo: String, novoSku: String, novoTipoProduto: number) : Promise<any[]> {
    return await this.requestsService.post(`${this.url}/duplicate/${produto.id}`, {
      codigo: novoCodigo,
      sku: novoSku,
      tipo_produto: novoTipoProduto,
    }).toPromise() as unknown as any;
  }

}
