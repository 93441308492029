<div class="all">
    <div class="top">
        <a [href]="modelo ? ('/produto/' + modelo.produto.id) : '.'" class="voltar"><img src="/assets/icons/voltar.svg" /></a>
    
        <div style="flex-grow: 1; margin-top: 2px;">
            <div class="title">{{modelo ? modelo.produto.codigo + ' - ' + modelo.cor.nome : ''}}</div>
            <div class="tamanhos">{{getTamanhos()}}</div>
        </div>
        
        <app-face-input [(modelo)]="modelo" [multiple]="true" (onInit)="onUploadImageInit($event)" (onFinish)="onUploadImageFinish($event)"></app-face-input>
        <app-regular-button [title]="'Salvar'" (onClick)="salvar()"></app-regular-button>
    </div>
    
    <div class="row">
        <div class="left">

            <div class="face" id="face{{face.id}}" *ngFor="let face of modelo?.produto?.tipo_produto?.faces" (click)="selectFace(face.nome)">
                <div>{{face.nome}}</div>
                <img src="{{getThumbnail(faces.get(face.nome))}}" (error)="onErrorThumbnail(faces.get(face.nome))">
            </div>
        </div> 
    
        <div class="middle">
            <app-canvas [img]="getThumbnail(selectedFace)" [face]="selectedFace" [modelo]="modelo"></app-canvas>
        </div>
    
        <div class="right">
            <app-face-input [(modelo)]="modelo" [(face)]="selectedFace" (onInit)="onUploadImageInit($event)" (onFinish)="onUploadImageFinish($event)"></app-face-input>

            <div class="text-options-container" [style.display]="faceTemCustomizacao() ? 'block' : 'none'">
                <app-regular-button class="predefinicao-button" [icon]="'/assets/icons/save.svg'" (onClick)="savePredefinicao()"></app-regular-button>
                <app-regular-button class="predefinicao-button" [icon]="'/assets/icons/open.svg'" (onClick)="loadPredefinicao()"></app-regular-button>
                <div class="title">Texto</div>


                <div class="input-with-button">
                    <app-regular-input [(input)]="selectedFace && selectedFace.x" 
                        [type]="'number'"
                        [icon]="'/assets/icons/x.png'">
                    </app-regular-input>
                    <app-regular-button [secondary]="true" [icon]="'/assets/icons/center.png'" (onClick)="centerHorizontally()"></app-regular-button>
                </div>

                <app-regular-input [(input)]="selectedFace && selectedFace.y" 
                    [type]="'number'"
                    [icon]="'/assets/icons/y.png'">
                </app-regular-input>

                <app-regular-input [(input)]="selectedFace && selectedFace.width" 
                    [type]="'number'"
                    [icon]="'/assets/icons/width.svg'">
                </app-regular-input>

                <app-regular-input [(input)]="selectedFace && selectedFace.height" 
                    [type]="'number'"
                    [icon]="'/assets/icons/height.svg'">
                </app-regular-input>
                
                <app-regular-select [(selectedOption)]="selectedFace && selectedFace.font_family"
                    [options]="fonts"
                    [valuePath]="'nome'"
                    [icon]="'/assets/icons/font.svg'">
                </app-regular-select>

                <app-regular-input [(input)]="selectedFace && selectedFace.font_size" 
                    [type]="'number'"
                    [icon]="'/assets/icons/font_size.svg'">
                </app-regular-input>

                <app-color-input
                    [(input)]="selectedFace && selectedFace.font_color" [title]="'Cor da fonte'"></app-color-input>

                <div class="subtitle">Bordas</div>

                <div class="border" *ngFor="let border of (selectedFace ? selectedFace.borders : [])">
                    
                    <div>
                        <app-regular-input [(input)]="border.kernel_size" 
                            [type]="'number'"
                            [icon]="'/assets/icons/weight.svg'">
                        </app-regular-input>
        
                        <app-color-input
                            [(input)]="border.color" [title]="'Cor da borda'"></app-color-input>
                    </div>

                    <app-regular-button [secondary]="true" [title]="'X'" (onClick)="removeBorder(border)"></app-regular-button>
                    
                </div>

                <app-regular-button [secondary]="true" [title]="'Nova borda'" (onClick)="addBorder()"></app-regular-button>
            </div>
        </div>
    </div>
</div>
<app-loading-screen></app-loading-screen>