<div class="container">
    <h1>Duplicar produto</h1>
    
    <div>

        <form [formGroup]="form" #produtoForm="ngForm" (ngSubmit)="duplicate(form.value)" class="form">

            <div>
                <app-regular-input [(form)]="form" [name]="'codigo'" [placeholder]="'Código'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
            </div>
        
            <div>
                <app-regular-input [(form)]="form" [name]="'sku'" [placeholder]="'SKU'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
            </div>
        
            <div>
                <app-regular-select [icon]="'/assets/icons/caixa.svg'" [options]="tipos" [(selectedOption)]="selectedOption" [(form)]="form" [name]="'tipo_produto'"></app-regular-select>
            </div>
        
            <app-regular-button [title]="'Cancelar'" (click)="close()" [secondary]="true"></app-regular-button>
            <app-regular-button [title]="'Duplicar'" (click)="duplicate(form.value)"></app-regular-button>
        </form>

    </div>
</div>