import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { DownloadPedidosService } from '../download-pedidos.service';
import { GerarService } from '../gerar.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { Paginacao } from '../model/Paginacao';
import { Pedido } from '../model/Pedido';
import { PedidoService } from '../pedido.service';
import { RequestsService } from '../requests.service';
import { MatDialog } from '@angular/material/dialog';
import { DeletePedidoModalComponent } from '../delete-pedido-modal/delete-pedido-modal.component';
import { IntegracaoLojaIntegradaService } from '../integracao-loja-integrada.service';
import { DownloadXmlService } from '../download-xml.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-lista-pedidos',
  templateUrl: './lista-pedidos.component.html',
  styleUrls: ['./lista-pedidos.component.less']
})
export class ListaPedidosComponent implements AfterViewInit {

  @ViewChild(LoadingScreenComponent) loadingScreen: LoadingScreenComponent;
  pedidoPaginacao: Paginacao<Pedido> = null;
  page: number = 0;
  pageSize: number = 5;
  currentSearch: string = "";
  searchInput: string = "";
  timestamp = new Date().getTime();
  downloadList: any[] = [];
  permissions: any;
  listOfStatus: any[] = [
    {id: 'all', nome: 'Todos'},
    {id: 'waiting_logo', nome: 'Esperando logo'},
    {id: 'sem_base', nome: 'Apenas sem base'},
  ];
  statusInput: any;
  numWaitingLogo: number | null = null;
  
  constructor(
    private pedidoService: PedidoService, 
    private router: Router, 
    private downloadPedidosService: DownloadPedidosService,
    private downloadXmlService: DownloadXmlService,
    private gerarService: GerarService,
    private dialog: MatDialog,
    private integracaoLojaIntegradaService: IntegracaoLojaIntegradaService,
  ) {
    this.permissions = AuthService.getUserPermissions();
    this.statusInput = this.listOfStatus[0];
  }

  ngAfterViewInit(): void {
    this.getPedidos();
  }

  async getPedidos() {
    this.loadingScreen.startLoading();
    this.pedidoService.countWaitingLogo().then(x => this.numWaitingLogo = x);
    this.pedidoPaginacao = await this.pedidoService.getAll(
      this.currentSearch,
      this.page + 1,
      this.pageSize,
      null,
      { status: this.statusInput.id }
    ) as unknown as Paginacao<Pedido>;
    this.loadingScreen.stopLoading();
  }

  onPageChange(event: PageEvent){
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getPedidos();
  }

  onClickNovo() {
    this.router.navigate([`pedido/novo`]);
  }

  async onSearch() {
    this.currentSearch = this.searchInput;
    this.page = 0;
    this.getPedidos();
  }

  async onClearSearch() {
    this.currentSearch = "";
    this.searchInput = "";
    await this.getPedidos();
  }

  onChangeStatus(newValue: any) {
    this.statusInput = newValue;
    this.getPedidos();
  }

  getThumbnail(item) {
    if(!item.produto) {
      return item.imagem_url ? item.imagem_url : "/assets/img/Frente-none.jpg";
    }

    let modelosCorIgual = item.produto.modelos.filter(x => x.cor.id === item.cor_id);
    let modelosCorIgualAndIncluiTamanho = modelosCorIgual.filter(x => x.tamanhos.map(y => y.id).includes(item.tamanho_id));

    if(modelosCorIgualAndIncluiTamanho.length == 0) {
      return item.imagem_url ? item.imagem_url : "/assets/img/Frente-none.jpg";
    }

    let modeloId = modelosCorIgualAndIncluiTamanho[0].id;

    return `${RequestsService.BASE}storage/produtos/${item.produto.codigo}/${modeloId}/thumbnail/Frente.jpg?t=${this.timestamp}`;
  }

  getStatusStyle(pedido) {
    let bgColor = {
      success: '#179b17',
      partial_success: '#ffa378',
      generate_at_night: '#ffe143',
      waiting: '#ffe143',
      error: 'red',
      expired: 'gray',
      waiting_payment: '#1344ad',
      cancelled: 'red',
      downloaded: '#098009',
      waiting_logo: '#ffa378',
    }[pedido.status];

    let color = {
      success: 'white',
      partial_success: 'black',
      generate_at_night: 'black',
      waiting: 'black',
      error: 'white',
      expired: 'white',
      waiting_payment: 'white',
      cancelled: 'white',
      downloaded: 'white',
      waiting_logo: 'black',
    }[pedido.status];

    return `background: ${bgColor}; color: ${color}`;
  }

  getStatusDisplay(pedido) {
    return {
      success: 'Concluído',
      partial_success: "Gerado parcialmente",
      generate_at_night: "Geração de noite",
      waiting: 'Processando',
      error: 'Falha',
      expired: 'Expirado',
      waiting_payment: 'Aguardando pagamento',
      cancelled: 'Cancelado',
      downloaded: 'Baixado',
      waiting_logo: 'Esperando logo',
    }[pedido.status];
  }

  toggleDownloadList(pedido) {
    if (this.downloadList.includes(pedido.id)) {
      this.downloadList = this.downloadList.filter(c => c !== pedido.id);
    } else {
      this.downloadList.push(pedido.id);
    }
  }

  async regenerate(pedido) {
    await this.gerarService.gerar([pedido.id]);
    this.getPedidos();
  }

  async onDownloadPedidos() {
    this.loadingScreen.startLoading();
    try {
      await this.downloadPedidosService.download(this.downloadList);
      this.loadingScreen.stopLoading();
      this.getPedidos();
    } catch (e) {
      alert(await e.error.text());
      this.loadingScreen.stopLoading();
    }
  }

  async onDownloadXml(pedido: Pedido) {
    this.loadingScreen.startLoading();
    await this.downloadXmlService.download(pedido);
    this.loadingScreen.stopLoading();
  }

  openLogs(pedido: Pedido) {
    let newRelativeUrl = this.router.createUrlTree([`log/pedido/${pedido.id}`]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  openDeleteModal(pedido) {
    const dialogRef = this.dialog.open(
      DeletePedidoModalComponent,
      { 
        data: {
          pedido: pedido,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
          successCallback: () => this.getPedidos(),
        }
      },
    );
  }

  onClickEditarPedido(pedido) {
    this.router.navigate([`pedido/edit/${pedido.id}`]);
  }

  async onClickSincronizarAgora() {
    this.loadingScreen.startLoading();
    await this.integracaoLojaIntegradaService.integrar();
    await this.getPedidos();
  }

  isItemGenerated(pedido : any, item : any) {
    return item.gerado && (pedido.status === 'success' || pedido.status === 'downloaded' || pedido.status === 'partial_success');
  }

}
