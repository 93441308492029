import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import panzoom from 'panzoom';
import { Face } from '../model/Face';
import { Modelo } from '../model/Modelo';
import { Regua } from '../model/Regua';

declare var interact: any;

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.less']
})
export class CanvasComponent implements OnInit {

  @Input('img') img;
  @Input('face') face : Face;
  @Input('modelo') modelo : Modelo;
  @ViewChild('imgElement') imgElement : ElementRef;
  @ViewChild('content') content : ElementRef;
  @ViewChild('container') container : ElementRef;

  originalWidth : number = 1;
  originalHeight : number = 1;
  currentZoomScale = 0.5;
  isMouseHoverText = false;

  reguas: Regua[];
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor() { 
    this.setupMoveListener();
    this.setupSelectionInteract()
  }

  ngOnInit() : void {}

  getLeft() {
    if (this.face === undefined) {
      return 0;
    }
    return (this.face.x - this.face.width / 2) / this.originalWidth * 100;
  }

  getTop() {
    if (this.face === undefined) {
      return 0;
    }
    return (this.face.y - this.face.height / 2) / this.originalHeight * 100;
  }

  getWidth() {
    if (this.face === undefined) {
      return 0;
    }
    return this.face.width / this.originalWidth * 100;
  }

  getHeight() {
    if (this.face === undefined) {
      return 0;
    }
    return this.face.height / this.originalHeight * 100;
  }

  faceTemCustomizacao() {
    if (this.modelo === undefined || this.face === undefined) {
      return false;
    }

    const facesSemCustomizacao = ['Bandana', 'Capuz']
    const nomeFaceAtual = this.modelo.produto.tipo_produto.faces.find(f => f.id == this.face.face_id).nome;

    return !facesSemCustomizacao.includes(nomeFaceAtual);
  }

  ngAfterViewInit(): void {
    this.setupPanzoom();
  }

  centerHorizontally() {
    this.face.x = Math.floor(this.getReguaAtual().left * 8);
  }

  setupPanzoom() {
    let that = this;
    this.imgElement.nativeElement.onload = function() {
      that.originalWidth = this.naturalWidth * 8;
      that.originalHeight = this.naturalHeight * 8;

      let x = that.container.nativeElement.clientWidth
        - that.content.nativeElement.clientWidth / 2;

      let y = that.container.nativeElement.clientHeight
        - that.content.nativeElement.clientHeight / 2;

      var zoom = panzoom(that.content.nativeElement, {
        initialZoom: that.currentZoomScale,
        initialX: x,
        initialY: y,
        beforeMouseDown: function(e) {
          var shouldIgnore = that.isMouseHoverText;
          return shouldIgnore;
        },
        filterKey: function(/* e, dx, dy, dz */) {
          // don't let panzoom handle this event:
          return true;
        }
      });

      zoom.on('zoom', (e: any) => {
        that.currentZoomScale = e.getTransform().scale;
      });

      that.setupReguas();
    };
  }

  setupSelectionInteract() {
    interact('#text')
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        listeners: {
          move: (event) => {
            this.ensureFaceAttributesAreNumber();
            this.face.width = event.rect.width * 8 / this.currentZoomScale;
            this.face.height = event.rect.height * 8 / this.currentZoomScale;
            this.face.x += (event.deltaRect.left + event.deltaRect.right)  * 4 / this.currentZoomScale;
            this.face.y += (event.deltaRect.top + event.deltaRect.bottom) * 4 / this.currentZoomScale;
          }
        },
        modifiers: [
        ],
        margin: 5,
      })
      .draggable({
        listeners: { move: (window as any).dragMoveListener },
        modifiers: [
          interact.modifiers.restrictRect({
            endOnly: true
          })
        ]
      }).
      on('dragend resizeend', (event) => {
        this.face.width = Math.floor(this.face.width);
        this.face.height = Math.floor(this.face.height);
        this.face.x = Math.floor(this.face.x);
        this.face.y = Math.floor(this.face.y);
      });
  }

  setupMoveListener() {
    let w = window as any;

    w.dragMoveListener = (event) => {
      this.ensureFaceAttributesAreNumber();
      this.face.x += event.dx * 8 / this.currentZoomScale;
      this.face.y += event.dy * 8 / this.currentZoomScale;
    }
  }

  onMouseHover(isHover) {
    this.isMouseHoverText = isHover;
  }

  @HostListener('document:keydown', ['$event'])
  moveSelected(event: KeyboardEvent) {
    //Verifica se não tá selecionado um input ou textarea
    if (event.target instanceof HTMLInputElement
       || event.target instanceof HTMLTextAreaElement
       || event.target instanceof HTMLSelectElement) { return };

    let jump = 8;

    if(event.shiftKey) {
      jump = 56;
    }

    if(event.altKey) {
      jump = 1;
    }

    event.preventDefault();

    switch(event.key) {
      case "ArrowRight":
        this.face.x += jump;
        break;
      case "ArrowLeft":
        this.face.x -= jump;
        break;
      case "ArrowUp":
        this.face.y -= jump;
        break;
      case "ArrowDown":
        this.face.y += jump;
        break;
    }
  }

  ensureFaceAttributesAreNumber() {
    if(typeof this.face.x === 'string') {
      this.face.x = parseInt(this.face.x);
    }

    if(typeof this.face.y === 'string') {
      this.face.y = parseInt(this.face.y);
    }

    if(typeof this.face.width === 'string') {
      this.face.width = parseInt(this.face.width);
    }

    if(typeof this.face.height === 'string') {
      this.face.height = parseInt(this.face.height);
    }
  }

  setupReguas() {
    
    this.reguas = [
      {
        left: this.originalWidth / 16,
        faceId: 1,
      },
      {
        left: this.originalWidth / 16,
        faceId: 2,
      },
      {
        left: this.originalWidth / 16,
        faceId: 3,
      },
      {
        left: this.originalWidth / 16,
        faceId: 4,
      }
    ];
  }

  getReguaAtual() {
    return this.reguas.find(r => r.faceId == this.face.face_id);
  }

  onContextMenu(regua, event: MouseEvent) {
    event.preventDefault();
    
    this.contextMenuPosition.x = event.x + 'px';
    this.contextMenuPosition.y = event.y + 'px';
    this.contextMenu.openMenu();
  }

  centerInCanvas() {
    var reguaAtual = this.getReguaAtual();
    reguaAtual.left = this.originalWidth / 16;
  }

}
