import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsResolveService implements Resolve<any> {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let firstSegment = route.url[0].path;
    let permissions = AuthService.getUserPermissions();

    if (!permissions) {
      this.authService.logout();
      this.router.navigate(['login']);
      return;
    }

    let dependencies = {
      editor: (permissions?.produto?.all),
      produto: (permissions?.produto?.all),
      pedido: (permissions?.pedido?.all),
      user: (permissions?.user?.all),
      log: (permissions?.user?.all),
      config: (permissions?.user?.all),
    };

    if (dependencies[firstSegment]) {
      return null;
    } else {
      this.router.navigate(['forbidden']);
    }
  }
}
