<div class="container">
    <div class="header">
      <div class="useful-area">
        <div class="header-content">
          <h1 id="title"><a href="/">Customiza</a></h1>
          <nav>
            <a *ngIf="permissions?.user?.all" [routerLink]="['/config']" [routerLinkActive]="['is-active']">Configurações</a>
            <a *ngIf="permissions?.user?.all" [routerLink]="['/log']" [routerLinkActive]="['is-active']">Logs</a>
            <a *ngIf="permissions?.user?.all" [routerLink]="['/user']" [routerLinkActive]="['is-active']">Usuários</a>
            <a *ngIf="permissions?.produto?.all" [routerLink]="['/produto']" [routerLinkActive]="['is-active']">Produtos</a>
            <a *ngIf="permissions?.pedido?.all" [routerLink]="['/pedido']" [routerLinkActive]="['is-active']">Pedidos</a>
          </nav>
        </div>
      </div>
    </div>
  
    <div class="content useful-area">
      <router-outlet></router-outlet>
    </div>
  
    <div class="footer">
      <div class="useful-area">
        Desenvolvido por DIMO
      </div>
    </div>
  
</div>