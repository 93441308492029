<div class="top">
    <h2 class="title">Itens</h2>
</div>

<div class="container-botoes-topo">
    <input type="file" id="file" class="inputfile">
    <label class="import-sheet" for="file">Importar planilha do Excel</label>

    <input
        type="file"
        id="file_logo"
        class="inputfile"
        (change)="onSelectImage($event.target, $event.target.files, -1)"
    >
    <label class="import-sheet" for="file_logo">Carregar nova imagem para todos os itens</label>
</div>

<div class="container-item" *ngFor="let item of itens; let i = index">
    
    <div class="item">
        <div class="item-label-group">
            <label class="item-label" style="flex-basis: 70px;">
                <span>Item</span>
                <app-regular-input [(input)]="item.numero" [icon]="'/assets/icons/codigo.svg'" [readonly]="true"></app-regular-input>
            </label>
            <div class="space-between"></div>
            <label class="item-label" style="flex-grow: 1;">
                <span>Produto</span>
                <app-regular-select [(selectedOption)]="item.produto"
                    [name]="'produto'+i"
                    [select2]="true"
                    [icon]="'/assets/icons/caixa.svg'"
                    (onChange)="onChangeProduto($event, item)"
                    [options]="produtos">
                </app-regular-select>
            </label>
        </div>
        
        <div class="item-label-group">
            <label class="item-label" style="flex-grow: 1;">
                <span>Cor</span>
                <app-regular-select [(selectedOption)]="item.cor"
                    [icon]="'/assets/icons/cor.svg'"
                    [idProperty]="'id'"
                    [options]="item.produto && item.produto.cores ? item.produto.cores : cores">
                </app-regular-select>
            </label>
            <div class="space-between"></div>
            <label class="item-label" style="flex-grow: 1;">
                <span>Tamanho</span>
                <app-regular-select [(selectedOption)]="item.tamanho"
                    [icon]="'/assets/icons/width.svg'"
                    [options]="tamanhos">
                </app-regular-select>
            </label>
        </div>
        
        <label class="item-label">
            <span>Frente</span>
            <textarea rows="2" class="textarea-input" [(ngModel)]="item.frente">{{item.frente}}</textarea>
        </label>
        <label class="item-label">
            <span>Costas</span>
            <textarea rows="2" class="textarea-input" [(ngModel)]="item.costas">{{item.costas}}</textarea>
        </label>

        <div class="item-checkbox-imagem">
            <label>
                <input
                    type="checkbox"
                    [checked]="item.tem_logo === 1"
                    (change)="item.tem_logo = (item.tem_logo === 0 ? 1 : 0)"
                />
                Possui imagem
            </label>
        </div>
    
        <input [(ngModel)]="item.produto_sku" type="hidden"/>
        <input [(ngModel)]="item.imagem_url" type="hidden"/>
        <input [(ngModel)]="item.produto_url" type="hidden"/>
    
        <div class="item-options">
            <label class="item-remove">
                <span (click)="duplicateItem(item)">Duplicar item</span>
            </label>
    
            <label class="item-remove">
                <span (click)="removeItem(item.numero)">Remover item</span>
            </label>
        </div>
    </div>

    <div class="container-item-logo" *ngIf="item.tem_logo === 1">
        <div class="bg-logo">
            <img [src]="(item.logo_url || (item.logo_url_original || '/assets/img/imagem_nao_definida.png'))" />
        </div>
        <div class="container-logo-actions">
            <input
                type="file"
                [id]="'file_logo_' + item.numero"
                class="inputfile"
                accept="image/png"
                (change)="onSelectImage($event.target, $event.target.files, item.numero)"
            >
            <label class="import-logo-unico" [for]="'file_logo_' + item.numero">Carregar nova imagem</label>
            <a
                *ngIf="item.logo_url_original"
                [href]="item.logo_url_original"
                class="logo-original-link"
                target="_blank"
            >
                Acessar imagem {{ item.logo_url ? 'original' : '' }}
        </a>
        </div>
    </div>

    <div
        class="container-item-logo"
        *ngIf="item.tem_logo === 0"
        style="border-color: #eee; background: #eee;"
    ></div>

</div>

<div style="width: 360px;">
    <app-regular-button
        [title]="'Novo item'"
        (click)="onClickNovoItem()"
        [secondary]="true"
        fullWidth="true"
    ></app-regular-button>
</div>

<div style="margin-bottom: 15px;"></div>