import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { Cor } from '../model/Cor';
import { Modelo } from '../model/Modelo';
import { Produto } from '../model/Produto';
import { SlotsModelo } from '../model/SlotsModelo';
import { ModeloService } from '../modelo.service';
import { ProdutoService } from '../produto.service';
import { RequestsService } from '../requests.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteProdutoModalComponent } from '../delete-produto-modal/delete-produto-modal.component';
import { DeleteCorModalComponent } from '../delete-cor-modal/delete-cor-modal.component';
import { TornarCorPadraoModalComponent } from '../tornar-cor-padrao-modal/tornar-cor-padrao-modal.component';
import { DuplicateProdutoModalComponent } from '../duplicate-produto-modal/duplicate-produto-modal.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.less']
})
export class ProdutoComponent implements AfterViewInit {

  @ViewChild(LoadingScreenComponent) loadingScreen : LoadingScreenComponent;
  
  produto: Produto;
  clickedNovo = false;
  timestamp = new Date().getTime();

  cores: Cor[] = [];
  selectedCor: Cor;

  slots: Map<string, SlotsModelo> = new Map();
  numSlots: number = 0;

  permissions: any;

  constructor(
    private route: ActivatedRoute, 
    private produtoService: ProdutoService, 
    private router: Router,
    private modeloService: ModeloService,
    private dialog: MatDialog
  ) {
    this.setupCores();
    this.selectedCor = this.cores[0];
    this.permissions = AuthService.getUserPermissions();
  }

  ngAfterViewInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.getProduto(parseInt(id));
  }

  async getProduto(id: number) {
    this.loadingScreen.startLoading();
    this.produto = await this.produtoService.get(id);
    this.loadingScreen.stopLoading();
    this.slots = new Map();
    this.setupCores();
    for(let modelo of this.produto.modelos) {
      if(!this.slots.has(modelo.cor.nome)) {
        this.slots.set(modelo.cor.nome, new SlotsModelo());
        this.removeCor(modelo.cor);
      }

      let slotsModelo: SlotsModelo = this.slots.get(modelo.cor.nome);
      slotsModelo.setModelo(modelo);
      slotsModelo.calcNumDefined((modelo) => {
        return this.getThumbnail(modelo) !== "assets/img/Frente-none.jpg";
      });
    }

    this.numSlots = Array.from(this.slots.keys()).length;
  }

  onClickNovo() {
    this.clickedNovo = true;
  }

  async onClickCriar() {
    this.clickedNovo = false;

    this.loadingScreen.startLoading();
    await this.modeloService.criarModelos(this.produto, this.selectedCor);
    this.loadingScreen.stopLoading();

    this.removeCor(this.selectedCor);
    this.getProduto(this.produto.id);
  }

  getThumbnail(modelo: Modelo) {
    if(modelo === undefined || modelo.blueprints.length == 0) {
      return "assets/img/Frente-none.jpg";
    }
    
    return `${RequestsService.BASE}storage/produtos/${this.produto.codigo}/${modelo.id}/thumbnail/Frente.jpg?t=${this.timestamp}`;
  }

  goToEditorIf(modelo: Modelo, condition) {
    if (condition) {
      this.goToEditor(modelo);
    }
  }

  goToEditor(modelo: Modelo) {
    this.router.navigate([`editor/${modelo.id}`]);
  }

  removeCor(cor: Cor) {
    let index = this.cores.findIndex((c) => c.id == cor.id);
    if(index > -1) {
      this.cores.splice(index, 1)
    }

    this.selectedCor = this.cores[0];
  }

  goToEditProduto() {
    this.router.navigate([`produto/edit/${this.produto.id}`]);
  }

  openDeleteModal() {
    const dialogRef = this.dialog.open(
      DeleteProdutoModalComponent,
      { 
        data: {
          produto: this.produto,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
        }
      },
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(["produto"]);
      }
    });

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  openDuplicateModal() {
    const dialogRef = this.dialog.open(
      DuplicateProdutoModalComponent,
      { 
        data: {
          produto: this.produto,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
        }
      },
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([`/produto`]);
      }
    });

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  existeCorPadrao() {
    for(let modelo of this.produto.modelos) {
      if (modelo.cor.nome === 'Padrão') {
        return true;
      }
    }
    return false;
  }

  openTornarCorPadraoModal(slotModelo: SlotsModelo) {
    
    const dialogRef = this.dialog.open(
      TornarCorPadraoModalComponent,
      { 
        data: {
          produto: this.produto,
          cor: slotModelo.masculino1.cor,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
        }
      },
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       this.getProduto(this.produto.id);
      }
    });

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  openDeleteCorModal(slotModelo: SlotsModelo) {
    
    const dialogRef = this.dialog.open(
      DeleteCorModalComponent,
      { 
        data: {
          produto: this.produto,
          cor: slotModelo.masculino1.cor,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
        }
      },
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       this.getProduto(this.produto.id);
      }
    });

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  openLogs(produto: Produto) {
    let newRelativeUrl = this.router.createUrlTree([`log/produto/${produto.id}`]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
  }

  setupCores() {
    this.cores = [
      {
        id: 1,
        nome: "Padrão"
      },
      {
        id: 2,
        nome: "Vermelho"
      },
      {
        id: 3,
        nome: "Verde"
      },
      {
        id: 4,
        nome: "Azul"
      },
      {
        id: 5,
        nome: "Preto"
      },
      {
        id: 6,
        nome: "Rosa"
      },
      {
        id: 7,
        nome: "Lilás"
      },
      {
        id: 8,
        nome: "Cinza"
      }
    ];
  }

}
