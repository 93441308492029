<app-back-button [href]="'/produto'"></app-back-button>

<div class="top">
    <h2 class="title">Novo produto</h2>
</div>

<form [formGroup]="form" #produtoForm="ngForm" (ngSubmit)="onSubmit(form.value)" class="form">

    <div>
        <app-regular-input [(form)]="form" [name]="'codigo'" [placeholder]="'Código'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [name]="'sku'" [placeholder]="'SKU'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-select [icon]="'/assets/icons/caixa.svg'" [options]="tipos" [(selectedOption)]="selectedOption" [(form)]="form" [name]="'tipo_produto'"></app-regular-select>
    </div>

    <app-regular-button [title]="'Criar'" (click)="submitForm()"></app-regular-button>
</form>