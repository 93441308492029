import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectService implements CanActivate {

  constructor(
    private router: Router,
  ) { }

  canActivate() {
    let permissions = AuthService.getUserPermissions();
    if (permissions?.produto?.all) {
      this.router.navigate(['produto']);
    } else {
      this.router.navigate(['pedido']);
    }
    return true;
  }
}
