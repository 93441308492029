<div class="top">
    <div class="title-container">
        <h2 class="title">Pedidos</h2>
        <span class="waiting-logo-warning">
            <img
                *ngIf="numWaitingLogo > 0"
                src="/assets/icons/exclamation.svg"
                style="filter: brightness(0.99) invert(.48) sepia(.8) hue-rotate(-0deg) saturate(600%)"
            />
            <img
                *ngIf="numWaitingLogo === 0"
                src="/assets/icons/check.svg"
                style="filter: brightness(0.99) invert(.4) sepia(.8) hue-rotate(49deg) saturate(600%)"
            />
            {{numWaitingLogo === null ? '...' : numWaitingLogo + ' esperando logo' }}
        </span>
    </div>
    <app-regular-button id="btn-sincronizar-agora" [title]="'Sincronizar agora'" [secondary]="true" (click)="onClickSincronizarAgora()"></app-regular-button>
    <app-regular-button [title]="'Novo'" (click)="onClickNovo()"></app-regular-button>
    <app-regular-input (enter)="onSearch()" 
        style="margin-left: 6px; width: 220px;" [(input)]="searchInput" [icon]="'/assets/icons/lupa.svg'" [placeholder]="'Pesquisar'"></app-regular-input>
    <app-regular-button [title]="'Pesquisar'" (click)="onSearch()" style="margin-left: 6px;"></app-regular-button>
    <app-regular-select [(selectedOption)]="statusInput"
        [icon]="'/assets/icons/status.svg'"
        (onChange)="onChangeStatus($event)"
        [options]="listOfStatus"
        style="margin-left: 6px;"
    ></app-regular-select>
</div>

<p class="active-search-message" [hidden]="currentSearch === ''">
    Exibindo resultados para <b>{{currentSearch}}</b><span (click)="onClearSearch()">(limpar)</span>
</p>

<div class="pedidos" *ngIf="pedidoPaginacao !== null && pedidoPaginacao.data.length !== 0">
    <div class="pedido" *ngFor="let pedido of pedidoPaginacao.data">
        <div class="pedido-container">
            <!-- Menu 3 pontos -->
            <button id="menu-3-pontos" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opções">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
                <button mat-menu-item (click)="regenerate(pedido)">
                    <mat-icon>sync</mat-icon>
                    <span>{{ pedido.status === 'waiting_payment' ? 'Forçar geração' : 'Gerar novamente' }}</span>
                </button>

                <button mat-menu-item (click)="onDownloadXml(pedido)">
                    <mat-icon>download</mat-icon>
                    <span>Baixar XML</span>
                </button>

                <button mat-menu-item (click)="onClickEditarPedido(pedido)">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                </button>

                <button mat-menu-item (click)="openDeleteModal(pedido)">
                    <mat-icon>delete</mat-icon>
                    <span>Deletar</span>
                </button>

                <button *ngIf="permissions?.user?.all" mat-menu-item (click)="openLogs(pedido)">
                    <mat-icon>list_alt</mat-icon>
                    <span>Logs</span>
                </button>
            </mat-menu>

            <span class="pedido-status" [style]="getStatusStyle(pedido)">{{getStatusDisplay(pedido)}}</span>

            <h3>
                <input 
                    *ngIf="pedido.status === 'success' || pedido.status === 'downloaded' || pedido.status === 'partial_success'"
                    class="checkbox-download"
                    type="checkbox"
                    (click)="toggleDownloadList(pedido)"
                    [checked]="downloadList.includes(pedido.id)" />
                {{pedido.codigo}}<span> · {{pedido.created_at | date: "dd/MM/yyyy 'às' HH:mm"}}</span>
            </h3>
            <ol class="itens-container">
                <li class="item" *ngFor="let item of pedido.itens" [style.backgroundColor]="isItemGenerated(pedido, item) ? '#c2ebc2' : ''">
                    <div class="item-imagem">
                        <img [src]="getThumbnail(item)">
                    </div>
                    <div class="item-detalhes">
                        <p><b>Item:</b> {{item.numero}}</p>
                        <p *ngIf="!item.produto_url"><b>Produto:</b> {{item.produto ? item.produto.codigo : "Não cadastrado (SKU " + item.produto_sku + ")" }}</p>
                        <p *ngIf="item.produto_url">
                            <a href="{{item.produto_url}}" target="_blank">
                                <b>Produto:</b> {{item.produto ? item.produto.codigo : "Não cadastrado (SKU " + item.produto_sku + ")" }}
                            </a>
                        </p>
                        <p><b>Cor:</b> {{item.cor.nome}}</p>
                        <p><b>Tamanho:</b> {{item.tamanho.nome}}</p>
                        <p><b>Texto frente:</b> {{item.frente}} <i *ngIf="item.frente === ''" style="color: #505050">[sem texto]</i></p>
                        <p><b>Texto costas:</b> {{item.costas}} <i *ngIf="item.costas === ''" style="color: #505050">[sem texto]</i></p>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</div>

<div
    *ngIf="pedidoPaginacao !== null && pedidoPaginacao.data.length === 0"
    style="padding: 10px 15px; background: #ddd; border-radius: 6px; margin: 12px 0;"
>
    Nenhum pedido encontrado.
</div>

<mat-paginator
    *ngIf="pedidoPaginacao !== null && pedidoPaginacao.data.length !== 0"
    [pageSize]="pageSize"
    [pageIndex]="page"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    [length]="pedidoPaginacao.total"
    (page)="onPageChange($event)"
></mat-paginator>

<a [class.downloadActive]="downloadList.length > 0" class="download-pedidos-button" (click)="onDownloadPedidos()">
    <span>Baixar · {{downloadList.length}} pedido{{downloadList.length > 1 ? 's' : ''}} selecionado{{downloadList.length > 1 ? 's' : ''}}</span>
</a>

<app-loading-screen></app-loading-screen>
