import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.component.html',
  styleUrls: ['./menu-layout.component.less']
})
export class MenuLayoutComponent implements OnInit {

  permissions: any;

  constructor() {
    this.permissions = AuthService.getUserPermissions();
  }

  ngOnInit(): void {
  }

}
