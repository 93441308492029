import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { LogService } from '../log.service';
import { Log } from '../model/Log';
import { Paginacao } from '../model/Paginacao';
import { MudancasModalComponent } from '../mudancas-modal/mudancas-modal.component';
import { PedidoService } from '../pedido.service';
import { ProdutoService } from '../produto.service';
import { UserService } from '../user.service';

@Component({
  selector: 'app-lista-logs',
  templateUrl: './lista-logs.component.html',
  styleUrls: ['./lista-logs.component.less']
})
export class ListaLogsComponent implements OnInit, AfterViewInit {

  @ViewChild(LoadingScreenComponent) loadingScreen: LoadingScreenComponent;
  logPaginacao: Paginacao<Log> = null;
  page: number = 0;
  pageSize: number = 20;

  logType = 'all';
  resourceId = '0';
  resourceName = '';

  constructor(
    private logService: LogService,
    private userService: UserService,
    private produtoService: ProdutoService,
    private pedidoService: PedidoService,
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngAfterViewInit(): void {
    let urlSlices = this.router.url.split('/');
    if (urlSlices.length > 2) {
      this.logType = urlSlices[2];
      this.resourceId = urlSlices[3];
    }
    this.getLogs();
  }

  async getLogs() {
    this.loadingScreen.startLoading();
    this.logPaginacao = await this.logService.getAll(`${this.logType}:${this.resourceId}`, this.page + 1, this.pageSize) as unknown as Paginacao<Log>;
    if (this.logType === 'user') {
      this.resourceName = (await this.userService.get(parseInt(this.resourceId))).name;
    } else if (this.logType === 'produto') {
      this.resourceName = (await this.produtoService.get(parseInt(this.resourceId))).codigo;
    } else if (this.logType === 'pedido') {
      this.resourceName = (await this.pedidoService.get(parseInt(this.resourceId))).codigo;
    }
    
    this.loadingScreen.stopLoading();
  }

  onPageChange(event: PageEvent){
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getLogs();
  }

  openMudancasModal(content) {
    const dialogRef = this.dialog.open(
      MudancasModalComponent,
      { 
        data: {
          content: content,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
        }
      },
    );

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

  action (a) {
    return {
      created: 'Criação',
      updated: 'Edição',
      deleted: 'Deleção',
      other: 'Outro',
    }[a];
  }

  resourceDesc(a) {
    return {user: 'usuário', produto: 'produto', pedido: 'pedido'}[a];
  }

}
