import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from './generic.service';
import { Pedido } from './model/Pedido';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class PedidoService extends GenericService<Pedido> {

  constructor(requestsService: RequestsService, router: Router) {
    super('api/pedido', requestsService, router);
  }
  
  async gerar(element: Object): Promise<Object> {
    return await this.requestsService.post(this.url, element).toPromise();
  }

  async countWaitingLogo(): Promise<number> {
    return await this.requestsService.get(this.url + "/countWaitingLogo").toPromise() as unknown as number;
  }

}
