import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.less']
})
export class ErrorModalComponent implements OnInit {

  @Input("show") show: boolean = false;
  @Input("message") message: string = "";

  constructor() { }

  ngOnInit(): void {
  }

}
