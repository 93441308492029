import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.less']
})
export class LoadingScreenComponent implements OnInit {
  @ViewChild('loadingScreen') loadingScreen : ElementRef;
  
  constructor() { }

  ngOnInit(): void {
  }

  startLoading() {
    this.loadingScreen.nativeElement.style.display = 'flex';
  }

  stopLoading() {
    this.loadingScreen.nativeElement.style.display = 'none';
  }

}
