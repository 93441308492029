import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-regular-button',
  templateUrl: './regular-button.component.html',
  styleUrls: ['./regular-button.component.less']
})
export class RegularButtonComponent implements OnInit {

  @Input("title") title: string = '';
  @Input("icon") icon: string = '';
  @Output("onClick") click: EventEmitter<void> = new EventEmitter();
  @Input("disabled") disabled: boolean;
  @Input("secondary") secondary: boolean = false;
  @Input("fullWidth") fullWidth: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onClick(event: Event) {
    event.preventDefault();
    this.click.emit();
  }

}
