import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeleteUserModalComponent } from '../delete-user-modal/delete-user-modal.component';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { Paginacao } from '../model/Paginacao';
import { User } from '../model/User';
import { UserService } from '../user.service';

@Component({
  selector: 'app-lista-user',
  templateUrl: './lista-user.component.html',
  styleUrls: ['./lista-user.component.less']
})
export class ListaUserComponent implements AfterViewInit {

  @ViewChild(LoadingScreenComponent) loadingScreen: LoadingScreenComponent;
  userPaginacao: Paginacao<User> = null;
  page: number = 0;
  pageSize: number = 100;

  constructor(private userService: UserService, private router: Router, private dialog: MatDialog) { }

  ngAfterViewInit(): void {
    this.getUsers();
  }

  async getUsers() {
    this.loadingScreen.startLoading();
    this.userPaginacao = await this.userService.getAll('', this.page + 1, this.pageSize) as unknown as Paginacao<User>;
    this.loadingScreen.stopLoading();
  }

  onClickNovo() {
    this.router.navigate([`user/novo`]);
  }

  onClickUser(user: User) {
    this.router.navigate([`user/edit/${user.id}`]);
  }

  openDeleteUser(userData: any, event) {
    event.stopPropagation();

    const user: User = {
      id: userData.id,
      name: '',
      email: '',
      password: '',
      permissions: {
        produto: { all: false },
        pedido: { all: false },
        user: { all: false },
      }
    };

    const dialogRef = this.dialog.open(
      DeleteUserModalComponent,
      { 
        data: {
          user: user,
          startLoadingScreen: () => this.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.loadingScreen.stopLoading(),
        }
      },
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
       this.getUsers();
      }
    });

    dialogRef.backdropClick().subscribe(_ => {
      dialogRef.close();
    })
  }

}
