import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { Face } from '../model/Face';
import { ModeloService } from '../modelo.service';

@Component({
  selector: 'app-face-input',
  templateUrl: './face-input.component.html',
  styleUrls: ['./face-input.component.less']
})
export class FaceInputComponent implements OnInit {

  @ViewChild('imageInput') imageInput;
  @Input('modelo') modelo;
  @Input('face') face: Face;
  @Output('onInit') onInit: EventEmitter<any> = new EventEmitter<any>(); 
  @Output('onFinish') onFinish: EventEmitter<any> = new EventEmitter<any>(); 
  @Input('multiple') multiple;

  constructor(private modeloService: ModeloService) { }

  ngOnInit(): void {
  }

  async uploadImage(event) {
    if(this.multiple) {
      this.uploadMultipleImages(event);
    } else {
      this.uploadSingleImage(event);
    }
  }

  async uploadSingleImage(event) {
    let file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    let data = new FormData();

    data.append("file", file);
    data.append('modelo_id', this.modelo.id);
    data.append('face_id', this.face.face_id.toString());

    this.onInit.emit(this.face);
    await this.modeloService.post('uploadFace', data);
    this.imageInput.nativeElement.value = "";
    this.onFinish.emit(this.face);
  }

  async uploadMultipleImages(event) {
    let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    this.onInit.emit(this.face);

    for(var file of files) {
      var faceId = this.getFaceIdByFileName(file.name);
      
      if(!faceId) continue;

      let data = new FormData();
      data.append("file", file);
      data.append('modelo_id', this.modelo.id);
      data.append('face_id', faceId.toString());

      await this.modeloService.post('uploadFace', data);

      await new Promise(resolve => setTimeout(resolve, 500))
    }
    
    this.imageInput.nativeElement.value = "";
    this.onFinish.emit(this.face);
  }

  private getFaceIdByFileName(fileName: string) {

    if(fileName.toLowerCase().includes("frente")) {
      return this.modelo.produto.tipo_produto.faces.find(a => a.nome == "Frente").id;
    }

    if(fileName.toLowerCase().includes("costa")) {
      return this.modelo.produto.tipo_produto.faces.find(a => a.nome == "Costas").id;
    }

    if(fileName.toLowerCase().includes("esquerda")) {
      return this.modelo.produto.tipo_produto.faces.find(a => a.nome == "Manga esquerda").id;
    }

    if(fileName.toLowerCase().includes("direita")) {
      return this.modelo.produto.tipo_produto.faces.find(a => a.nome == "Manga direita").id;
    }

    if(fileName.toLowerCase().includes("bd")) {
      return this.modelo.produto.tipo_produto.faces.find(a => a.nome == "Bandana").id;
    }

    if(fileName.toLowerCase().includes("capuz")) {
      return this.modelo.produto.tipo_produto.faces.find(a => a.nome == "Capuz").id;
    }

    return null;
  }

}
