import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ConfigService } from '../config.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { Tamanho } from '../model/Tamanho';
import { TamanhoService } from '../tamanho.service';
import { FonteService } from '../fonte.service';
import { Fonte } from '../model/Fonte';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.less']
})
export class ConfigComponent implements OnInit {

  form;
  tamanhosPesca: Tamanho[] = [];
  tamanhosPedal: Tamanho[] = [];
  fontes: Fonte[] = [];
  fonteForm;

  @ViewChild(LoadingScreenComponent) loadingScreen : LoadingScreenComponent;
  @ViewChild('configForm') configFormElement: NgForm;
  @ViewChild('addFonteForm') addFonteFormElement: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private tamanhoService: TamanhoService,
    private configService: ConfigService,
    private fonteService: FonteService,
  ) {
    this.form = this.formBuilder.group({
      offsetLogoY: new FormGroup({}),
    });
    this.fonteForm = this.formBuilder.group({
      nome: '',
      arquivo: null,
    });
  }

  addOffsetLogoYFields() {
    this.tamanhosPesca.forEach((tamanho) => {
      this.form.controls.offsetLogoY.addControl(tamanho.id, new FormControl());
      setTimeout(() => {
        this.form.controls.offsetLogoY.controls[tamanho.id].setValue(String(tamanho.offset_logo_y));
      });
    });
    this.tamanhosPedal.forEach((tamanho) => {
      this.form.controls.offsetLogoY.addControl(tamanho.id, new FormControl());
      setTimeout(() => {
        this.form.controls.offsetLogoY.controls[tamanho.id].setValue(String(tamanho.offset_logo_y));
      });
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const tamanhosPromise = this.loadTamanhos();
    const fontesPromise = this.loadFontes();

    this.loadingScreen.startLoading();
    Promise.all([tamanhosPromise, fontesPromise]).then(() => {
      this.loadingScreen.stopLoading();
      this.addOffsetLogoYFields();
    });
  }

  async loadTamanhos() {
    try {
      const tamanhos: Tamanho[] = await this.tamanhoService.getAll();
      this.tamanhosPesca = tamanhos.filter((tamanho) => tamanho.tipo === 'pesca');
      this.tamanhosPedal = tamanhos.filter((tamanho) => tamanho.tipo === 'pedal');
    } catch(e) {
      console.log(e);
      alert('Erro ao carregar tamanhos');
    }
  }

  async onSubmit(config) {
    const newOffsetLogoYValues = { ...config.offsetLogoY };

    this.loadingScreen.startLoading();
    try {
      await this.configService.updateOffsetLogoY(newOffsetLogoYValues);
    } catch(e) {
      console.log(e);
    }
    this.loadingScreen.stopLoading();
  }

  submitForm(): void {
    this.configFormElement.ngSubmit.emit();
  }

  async loadFontes() {
    try {
      this.fontes = (await this.fonteService.getAll()).filter((fonte) => {
        return !["Airstrike", "Road Rage", "Ethnocentric"].includes(fonte.nome);
      });
    } catch(e) {
      console.log(e);
      alert('Erro ao carregar fontes');
    }
  }

  onSelectFonte(input: HTMLInputElement, files: FileList) {
    const fileToUpload = files.item(0);

    input.value = '';

    this.fonteForm.controls.arquivo.setValue(fileToUpload || null);
    if (fileToUpload) {
      input.nextSibling.textContent = fileToUpload.name;
    }
  }

  submitFonteForm(): void {
    this.addFonteFormElement.ngSubmit.emit();
  }

  async onAddFonte() {
    const nome = this.fonteForm.controls.nome.value;
    const arquivo = this.fonteForm.controls.arquivo.value;

    if (!nome || !arquivo) {
      alert('Preencha todos os campos');
      return;
    }

    this.loadingScreen.startLoading();
    try {
      await this.fonteService.uploadFonte(nome, arquivo);
      await this.fonteService.create({
        id: null,
        nome
      });

      await this.loadFontes();
    } catch(e) {
      console.log(e);
      alert(e.error?.message || 'Erro ao adicionar fonte');
    }
    this.loadingScreen.stopLoading();
  }

}
