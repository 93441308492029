<app-back-button [href]="'/pedido'"></app-back-button>

<div class="top">
    <h2 class="title">{{ idPedido ? 'Editar' : 'Novo' }} pedido</h2>

    <app-regular-button (click)="reintegrar()" secondary="true" [title]="'Reintegrar'"></app-regular-button>
</div>

<form [formGroup]="form" #pedidoForm="ngForm" (ngSubmit)="onSubmit(form.value)" class="form">

    <div class="container-codigo-pedido">
        <app-regular-input 
            [(form)]="form"
            [name]="'codigo'"
            [placeholder]="'Código'"
            [icon]="'/assets/icons/codigo.svg'"
            [readonly]="idPedido"></app-regular-input>
    </div>

    <app-lista-itens [(getPedido)]="getPedido" [idPedido]="idPedido" [(itens)]="itens"></app-lista-itens>

    <input type="checkbox" checked id="generate_at_night" [formControlName]="'generate_at_night'" />
    <label for="generate_at_night">Gerar apenas de noite</label>
    
    <input type="checkbox" id="gerar_com_etiqueta_uv" [formControlName]="'gerar_com_etiqueta_uv'" />
    <label for="gerar_com_etiqueta_uv">Gerar com etiqueta UV</label>

    <app-regular-button [title]="idPedido ? 'Salvar' : 'Criar'" (click)="submitForm()"></app-regular-button>
</form>
<app-loading-screen></app-loading-screen>