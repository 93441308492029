import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Predefinicao } from '../model/Predefinicao';
import { PredefinicaoService } from '../predefinicao.service';

@Component({
  selector: 'app-delete-predefinicao-modal',
  templateUrl: './delete-predefinicao-modal.component.html',
  styleUrls: ['./delete-predefinicao-modal.component.less']
})
export class DeletePredefinicaoModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      predefinicao: Predefinicao,
      startLoadingScreen: any,
      stopLoadingScreen: any,
      successCallback: any,
    },
    private predefinicaoService: PredefinicaoService,
    public dialogRef: MatDialogRef<DeletePredefinicaoModalComponent>
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  async delete() {
    this.data.startLoadingScreen();
    try {
      await this.predefinicaoService.delete(this.data.predefinicao);
      await this.data.successCallback();
    } catch (e) {
      alert('Ocorreu algum erro durante a deleção desta predefinição.'); 
    }
    this.close();
    this.data.stopLoadingScreen();
  }

}
