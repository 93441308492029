<div class="container" *ngIf="predefinicoes !== null && predefinicoes.length !== 0">
    <h1>Carregar predefinição para esta face</h1>
    <ul>
        <li *ngFor="let predefinicao of predefinicoes" (click)="select(predefinicao)" [class.selected]="predefinicao == selected">
            {{ predefinicao.nome }}
            <div (click)="openDeleteModal(predefinicao, $event)">Deletar</div>
            <div *ngIf="predefinicao.is_default" style="margin-right: 16px; text-decoration: none; font-style: italic;">Padrão</div>
            <div *ngIf="!predefinicao.is_default" (click)="setDefault(predefinicao, $event)" style="margin-right: 16px;">Tornar padrão</div>
        </li>
    </ul>
    
    <app-regular-button [title]="'Carregar'" (click)="load()" [disabled]="selected === null"></app-regular-button>
    <app-regular-button [title]="'Fechar'" (click)="close()" [secondary]="true"></app-regular-button>
</div>

<div *ngIf="predefinicoes !== null && predefinicoes.length === 0">
    Nenhuma predefinição salva para esta face
</div>
