<div class="container">
    <div class="header">
      <div class="useful-area">
        <div class="header-content">
          <h1 id="title"><a href="/produto">Customiza</a></h1>
        </div>
      </div>
    </div>
  
    <div class="content useful-area">
        <div class="top">
            <h2 class="title">Login</h2>
        </div>
        
        <form [formGroup]="form" #loginForm="ngForm" (ngSubmit)="onSubmit(form.value)" class="form">
        
            <div>
                <app-regular-input [(form)]="form" [name]="'email'" [placeholder]="'E-mail'" [icon]="'/assets/icons/email.svg'"></app-regular-input>
            </div>
        
            <div>
                <app-regular-input [(form)]="form" [type]="'password'" [name]="'password'" [placeholder]="'Senha'" [icon]="'/assets/icons/chave.svg'"></app-regular-input>
            </div>

            <app-regular-button [title]="'Entrar'" (click)="submitForm()"></app-regular-button>
        </form>
    </div>

    <app-error-modal [message]="errorMessage" [show]="showErrorMessage"></app-error-modal>
  
    <div class="footer">
      <div class="useful-area">
        Desenvolvido por DIMO
      </div>
    </div>
  
</div>

