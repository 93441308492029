import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { Paginacao } from '../model/Paginacao';
import { Produto } from '../model/Produto';
import { ProdutoService } from '../produto.service';
import { RequestsService } from '../requests.service';

@Component({
  selector: 'app-lista-produtos',
  templateUrl: './lista-produtos.component.html',
  styleUrls: ['./lista-produtos.component.less']
})
export class ListaProdutosComponent implements AfterViewInit {

  @ViewChild(LoadingScreenComponent) loadingScreen: LoadingScreenComponent;
  produtoPaginacao: Paginacao<Produto> = null;
  page: number = 0;
  pageSize: number = 8;
  currentSearch: string = "";
  searchInput: string = "";
  orderBy: any = {};
  optionsOrderBy: any = [
    {id: 'most_recent', nome: 'Mais recentes'},
    {id: 'alphabetic', nome: 'Alfabética'},
  ];

  constructor(private produtoService: ProdutoService, private router: Router) { }

  ngAfterViewInit(): void {
    this.orderBy = this.optionsOrderBy[0];
    this.getProdutos();
  }

  async getProdutos() {
    this.loadingScreen.startLoading();
    this.produtoPaginacao = await this.produtoService.getAll(this.currentSearch, this.page + 1, this.pageSize, this.orderBy.id) as unknown as Paginacao<Produto>;
    this.loadingScreen.stopLoading();
  }

  onPageChange(event: PageEvent){
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getProdutos();
  }

  onClickNovo() {
    this.router.navigate([`produto/novo`]);
  }

  async onSearch() {
    this.currentSearch = this.searchInput;
    this.getProdutos();
  }

  onClickProduto(produto: Produto) {
    this.router.navigate([`produto/${produto.id}`]);
  }

  onChangeOrderBy(option) {
    this.orderBy = option;
    this.getProdutos();
  }

  async onClearSearch() {
    this.currentSearch = "";
    this.searchInput = "";
    await this.getProdutos();
  }

  getThumbnail(produto: Produto) {
    let modelo = produto.modelos.find(m => m.tamanhos[0].codigo === 'G')
    if(produto && modelo) {
      return `${RequestsService.BASE}storage/produtos/${produto.codigo}/${modelo.id}/thumbnail/Frente.jpg`;
    }

    return '/assets/img/Frente-none.jpg';
  }

}
