import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class GerarService {

  constructor(private http: HttpClient) {}

  async gerar(ids: number[]): Promise<any> {
    await this.http.post(`${RequestsService.BASE}api/pedido/gerar`, {
      pedidosIds: ids,
    }, {
      headers: new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`}),
    }).toPromise();
  }

}
