<div class="top">
    <h2 class="title">Usuários</h2>
    <app-regular-button [title]="'Novo'" (click)="onClickNovo()"></app-regular-button>
</div>

<div class="users" *ngIf="userPaginacao && userPaginacao?.data?.length !== 0">
    <div class="user" *ngFor="let user of userPaginacao.data">
        <div class="user-container" (click)="onClickUser(user)">
            <span>{{user.name}}</span>
            <span>{{user.email}}</span>
            <span style="text-align: right;"><u style="color: #bb0000" (click)="openDeleteUser(user, $event)">Deletar</u></span>
            <p [style.opacity]="user.permissions.produto_all ? '1' : '0.35'"><img src="/assets/icons/check.svg" />Produtos</p>
            <p [style.opacity]="user.permissions.pedido_all ? '1' : '0.35'"><img src="/assets/icons/check.svg" />Pedidos</p>
            <p [style.opacity]="user.permissions.user_all ? '1' : '0.35'"><img src="/assets/icons/check.svg" />Usuários</p>
        </div>
    </div>
</div>

<app-loading-screen></app-loading-screen>