import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-regular-input',
  templateUrl: './regular-input.component.html',
  styleUrls: ['./regular-input.component.less']
})
export class RegularInputComponent implements OnInit {

  @Input("icon") icon: string = "";
  @Input("placeholder") placeholder: string = "";
  @Input("form") form: FormGroup;
  @Input("name") name: string;
  @Output("enter") enter: EventEmitter<string> = new EventEmitter();
  @Input("input") input: string = "";
  @Output("inputChange") inputChange: EventEmitter<string> = new EventEmitter();
  @Input("type") type: string = "text";
  @Input("readonly") readonly: boolean = false;

  @Output("onChange") onChange: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onEnter() {
    this.enter.emit(this.input);
  }

  onChangeProperty(event) {
    this.inputChange.emit(this.input);
    this.onChange.emit(this.input);
  }

}
