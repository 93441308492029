import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from "file-saver";
import { AuthService } from './auth.service';
import { Pedido } from './model/Pedido';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadXmlService {

  constructor(private http: HttpClient) {}

  async download(pedido: Pedido): Promise<any> {
    this.http.post(`${RequestsService.BASE}api/pedido/downloadXml`, {pedidosId: pedido.id}, {
      responseType: "blob",
      headers: new HttpHeaders()
        .append("Content-Type", "application/json")
        .append("Authorization", `Bearer ${AuthService.getUserSession()}`)
    }).subscribe(
      data => {
        saveAs(data, 'xml_pedido_' + pedido.codigo + '.xml');
      }
    );
  }

}
