import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

declare var $;

@Component({
  selector: 'app-regular-select',
  templateUrl: './regular-select.component.html',
  styleUrls: ['./regular-select.component.less']
})
export class RegularSelectComponent implements OnInit {

  @Input("icon") icon: string = "";
  @Input("form") form: FormGroup;
  @Input("name") name: string;
  @Input('options') options;
  @Input('selectedOption') selectedOption;
  @Input('valuePath') valuePath: string;
  @Input('select2') select2: boolean = false;
  @Input("idProperty") idProperty: string;
  @Output('selectedOptionChange') selectedOptionChange = new EventEmitter();
  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();

  select2Object: any;

  constructor() { 
    
  }

  ngOnInit(): void {
    if(this.idProperty && this.selectedOption) {
      this.selectedOption = this.options.find((a) => a[this.idProperty] == this.selectedOption[this.idProperty]);
    }
  }
  
  ngAfterViewInit() {
    if(this.name && this.select2) {
      this.select2Object = $('.regular-select-'+this.name).select2();
  
      this.select2Object.on('select2:select', (event) => {
        const nome = event.params.data.text;
        const option = this.options.find((a) => a.nome == nome);
        this.selectedOption = option;
        this.onChangeValue(this.selectedOption);
      });

      if(this.selectedOption) {
        const index = this.options.findIndex((a) => a.nome == this.selectedOption.nome);
        this.select2Object.val(`${index + 1}: Object`).trigger('change');
      }
    }
  }

  ngOnChanges(changes: any): void {
    if(changes.selectedOption && this.select2Object) {
      const index = this.options.findIndex((a) => a.nome == this.selectedOption.nome);
      this.select2Object.val(`${index + 1}: Object`).trigger('change');
    }
  }

  onChangeValue(value) {
    if(this.onChange) {
      this.onChange.emit(this.selectedOption);
    }
    this.selectedOptionChange.emit(this.selectedOption);
  }

}
