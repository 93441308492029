import { Component, ElementRef, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';

declare var interact: any;

@Component({
  selector: 'app-regua',
  templateUrl: './regua.component.html',
  styleUrls: ['./regua.component.less']
})
export class ReguaComponent implements OnInit, AfterViewInit {

  @Input('left') left;
  @Output('leftChange') leftChange: EventEmitter<number> = new EventEmitter<number>();
  @Input('show') show: boolean;
  @Input('currentZoomScale') currentZoomScale;
  @ViewChild('regua') regua : ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if(this.regua) {
      interact(this.regua.nativeElement)
        .draggable({
          listeners: { move: (event) => {
            this.left += event.dx / this.currentZoomScale;
            this.leftChange.emit(this.left);
          }
        },
          modifiers: [
            interact.modifiers.restrictRect({
              endOnly: true
            })
          ]
        })
    }
  }

}
