import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Produto } from '../model/Produto';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-duplicate-produto-modal',
  templateUrl: './duplicate-produto-modal.component.html',
  styleUrls: ['./duplicate-produto-modal.component.less']
})
export class DuplicateProdutoModalComponent implements OnInit {

  form;

  tipos: any = [
    {
      id: 1,
      nome: "Gola padre"
    },
    {
      id: 3,
      nome: "Gola padre com bandana"
    },
    {
      id: 4,
      nome: "Camiseta pedal"
    },
    {
      id: 5,
      nome: "Capuz"
    },
    {
      id: 6,
      nome: "Novo gola careca"
    },
    {
      id: 7,
      nome: "Manga curta + gola careca"
    },
    {
      id: 8,
      nome: "Manga curta + gola padre"
    }
  ];
  selectedOption: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      produto: Produto,
      startLoadingScreen: any,
      stopLoadingScreen: any,
    },
    private produtoService: ProdutoService,
    public dialogRef: MatDialogRef<DuplicateProdutoModalComponent>,
    private router: Router,
    private formBuilder: FormBuilder, 

  ) {
    this.form = this.formBuilder.group({
      codigo: '',
      sku: '',
      tipo_produto: 1
    });
    this.selectedOption = this.tipos[0];
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  async duplicate(dados: any) {
    this.data.startLoadingScreen();
    try {
      
      var codigo: String = dados.codigo;
      var sku: String = dados.sku;
      var idTipoProduto: number = dados.tipo_produto.id;

      if(!codigo || !sku || !idTipoProduto) {
        alert("Preencha todos os campos!");
        this.data.stopLoadingScreen();
        return;
      }

      let response = await this.produtoService.duplicate(
        this.data.produto,
        codigo,
        sku,
        idTipoProduto
      ) as unknown as any;

      if(response) {
        this.dialogRef.close(response);
        this.data.stopLoadingScreen();
        return;
      }
      
    } catch (e) {
      alert('Ocorreu algum erro durante a duplicação deste produto.');
    }
    this.dialogRef.close(true);
    this.data.stopLoadingScreen();
  }

}
