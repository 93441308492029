import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cor } from '../model/Cor';
import { Produto } from '../model/Produto';
import { ModeloService } from '../modelo.service';

@Component({
  selector: 'app-tornar-cor-padrao-modal',
  templateUrl: './tornar-cor-padrao-modal.component.html',
  styleUrls: ['./tornar-cor-padrao-modal.component.less']
})
export class TornarCorPadraoModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      produto: Produto,
      cor: Cor,
      startLoadingScreen: any,
      stopLoadingScreen: any,
    },
    private modeloService: ModeloService,
    public dialogRef: MatDialogRef<TornarCorPadraoModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  async tornarPadrao() {
    this.data.startLoadingScreen();
    try {
      let response = await this.modeloService.tornarCorPadrao(this.data.produto, this.data.cor) as unknown as Object[];
      // this.router.navigate(['/produto']);
    } catch (e) {
      console.log(this.data);
      
      alert('Ocorreu algum erro durante a conversão desta cor para a cor padrão.');
    }
    this.dialogRef.close(true);
    this.data.stopLoadingScreen();
  }

}
