import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { Predefinicao } from './model/Predefinicao';
import { RequestsService } from './requests.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PredefinicaoService extends GenericService<Predefinicao> {

  constructor(requestsService: RequestsService, private http: HttpClient, router: Router) {
    super('api/predefinicao', requestsService, router);
  }
  
  async getByTamanhoEFace(tamanhoId: number, faceId: number) : Promise<Predefinicao[]> {
    let url = this.url + `?tamanho_id=${tamanhoId}&face_id=${faceId}`
    return await this.requestsService.get(url).toPromise() as unknown as Predefinicao[];
  }

  async setDefault(predefinicaoId: number) {
    await this.http.post(`${RequestsService.BASE}api/predefinicao/default`, {
      predefinicao_id: predefinicaoId,
    }, {
      headers: new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`}),
    }).toPromise();
  }

}