import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Item } from '../model/Item';
import { ProdutoService } from '../produto.service';
import { TamanhoService } from '../tamanho.service';
import { MediaService } from '../media.service';
import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'app-lista-itens',
  templateUrl: './lista-itens.component.html',
  styleUrls: ['./lista-itens.component.less']
})
export class ListaItensComponent implements AfterViewInit {

  @Input('idPedido') idPedido: any;
  @Input('getPedido') getPedido: any;
  @Input('itens') itens: Item[];
  @Output('itensChange') inputChange: EventEmitter<Object[]> = new EventEmitter<Object[]>();

  initializing: boolean = false;
  produtos: any[] = [];
  tamanhos: any[] = [];

  cores: any[];

  constructor(
    private tamanhoService: TamanhoService,
    private produtoService: ProdutoService,
    private mediaService: MediaService,
  ) {
    this.setupCores();
  }

  importNewItem(itemInfo : any) {
    const traducaoTamanho = {
      '2': 'Infantil 02',
      '4': 'Infantil 04',
      '6': 'Infantil 06',
      '8': 'Infantil 08',
      '10': 'Infantil 10',
      '12': 'Infantil 12',
      '14': 'Infantil 14',
      'p': 'Masculino P',
      'm': 'Masculino M',
      'g': 'Masculino G',
      'gg': 'Masculino GG',
      'ex': 'Masculino EX',
      'p feminino': 'Feminino P',
      'm feminino': 'Feminino M',
      'g feminino': 'Feminino G',
      'gg feminino': 'Feminino GG',
    };

    const p = this.produtos.find(p => String(p.nome).toLowerCase() === itemInfo.produto);
    const c = this.cores.find(c => String(c.nome).toLowerCase() === itemInfo.cor);
    const t = this.tamanhos.find(t => t.nome === traducaoTamanho[itemInfo.tamanho]);

    if (!p || !c || !t) {
      throw 'erro';
    }

    var novoItem : Item = {
      id: null,
      numero: this.itens.length + 1,
      produto: p,
      cor: c,
      tamanho: t,
      frente: itemInfo.frente,
      costas: itemInfo.costas,
      produto_sku: null,
      imagem_url: null,
      produto_url: null,
      tem_logo: 0,
      logo_url: null,
      logo_url_original: null,
    };

    this.itens.push(novoItem);
    this.inputChange.emit(this.itens);
  }

  ngAfterViewInit(): void {
    this.loadInputs();

    const input : any = document.getElementById('file');
    input.addEventListener('change', () => {
      readXlsxFile(input.files[0]).then((rows : any[]) => {
        this.itens.splice(0, 1000);
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];
          if (row[0] === null) {
            break;
          }

          try {
            const produto = String(row[0]).toLowerCase();
            const cor = String(row[1]).toLowerCase();
            const tamanho = String(row[2]).toLowerCase();
            const frente = String(row[3] ?? '');
            const costas = String(row[4] ?? '');

            this.importNewItem({
              produto,
              cor,
              tamanho,
              frente,
              costas,
            });
          } catch (e) {
            alert(`Erro na linha ${row[0]}, ${row[1]}, ${row[2]}, ${row[3]}, ${row[4]}`);
          }
        }
        input.value = '';
      })
    });
  }

  async loadInputs() {
    if (this.idPedido) {
      await Promise.all([
        this.getTamanhos(),
        this.getProdutos(),
        this.getPedido(this.idPedido),
      ]);

      setTimeout(() => {
        this.itens.forEach(item => {

          this.initializing = true;
          this.produtos.forEach(produto => {
            if (produto.id === item.produto?.id) {
              item.produto = produto;
              this.onChangeProduto(produto, item);
            }
          });
          this.initializing = false;

          this.tamanhos.forEach(tamanho => {
            if (tamanho.id === item.tamanho.id) {
              item.tamanho = tamanho;
            }
          });

        });
      });
    } else {
      this.getTamanhos();
      this.getProdutos();
      this.onAddItem();
    }
  }

  async getTamanhos() {
    var tamanhos = await this.tamanhoService.getAll();
    this.tamanhos = tamanhos.map(o => ({id: o.id, nome: o.nome}));
  }

  async getProdutos() {
    var produtos = await this.produtoService.getSelectList();
    this.produtos = produtos.map(o => ({ id: o.id, nome: o.codigo, cores: o.cores})).sort((a, b) => a.nome.localeCompare(b.nome));

    this.produtos.unshift({
      id: null, nome: "Nenhum", cores: null,
    });
  }

  onClickNovoItem() {
    this.onAddItem()
  }

  onChangeProduto(produto, item) {
    if (this.initializing) {
      produto.cores.forEach(cor => {
        if (cor.id === item.cor.id) {
          item.cor = cor;
        }
      });
    } else {
      item.cor = null;
    }
  }

  onAddItem() {
    var novoItem : Item = {
      id: null,
      numero: this.itens.length + 1,
      produto: null,
      cor: null,
      tamanho: null,
      frente: '',
      costas: '',
      produto_sku: null,
      imagem_url: null,
      produto_url: null,
      tem_logo: 0,
      logo_url: null,
      logo_url_original: null,
    };

    this.itens.push(novoItem);
    this.inputChange.emit(this.itens);
  }

  removeItem(numero) {
    this.itens = this.itens.filter(item => item.numero !== numero).map((item, i) => {
      return {
        ...item,
        numero: i + 1,
      };
    });
    this.inputChange.emit(this.itens);
  }

  duplicateItem(item) {
    this.itens = [...this.itens, {
      ...item,
      numero: this.itens.length + 1,
    }];
    this.inputChange.emit(this.itens);
  }

  onSelectImage(input: HTMLInputElement, files: FileList, itemNumero: number) {
    const fileToUpload = files.item(0);

    input.value = '';
    input.classList.add('loading-upload');

    this.mediaService.upload(fileToUpload).then((response) => {
      let { url } = response;

      input.classList.remove('loading-upload');
      // url = url.replace('https://king.dimo.com.br', 'http://localhost:8080');

      this.itens.forEach(item => {
        if (item.numero === itemNumero || itemNumero === -1) {
          item.logo_url = url;
        }
      });
    }).catch((error) => {
      input.classList.remove('loading-upload');
      alert('Erro ao carregar imagem');
    });
  }

  setupCores() {
    this.cores = [
      {
        id: 1,
        nome: "Padrão"
      },
      {
        id: 2,
        nome: "Vermelho"
      },
      {
        id: 3,
        nome: "Verde"
      },
      {
        id: 4,
        nome: "Azul"
      },
      {
        id: 5,
        nome: "Preto"
      },
      {
        id: 6,
        nome: "Rosa"
      },
      {
        id: 7,
        nome: "Lilás"
      },
      {
        id: 8,
        nome: "Cinza"
      }
    ];
  }

}
