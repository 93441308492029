<a class="delete-button" (click)="openDeleteModal()">Deletar produto</a>
<a class="duplicate-button" (click)="openDuplicateModal()">Duplicar produto</a>
<a class="edit-button" (click)="goToEditProduto()">Editar</a>
<a *ngIf="permissions?.user?.all" class="edit-button" (click)="openLogs(produto)">Logs</a>
<app-back-button [href]="'/produto'"></app-back-button>

<div class="content" *ngIf="produto">
    <div class="top">
        <img src="/assets/icons/camiseta.png" alt="Camiseta" />
        <div class="info">
            <h2>{{produto.codigo}} - SKU {{produto.sku ? produto.sku : '[não cadastrado]'}}</h2>
            <span>
                Tipo: {{produto.tipo_produto.nome}} |
                Criado em {{produto.created_at | date: "dd/MM/yyyy 'às' HH:mm"}}
            </span>
        </div>
    </div>
</div>

<div class="top header-cores">
    <h2 class="title">Cores</h2>
    <div *ngIf="!clickedNovo && cores.length > 0">
        <app-regular-button [title]="'Nova cor'" (onClick)="onClickNovo()"></app-regular-button>
    </div>

    <div class="row" *ngIf="clickedNovo && cores.length > 0">
        <app-regular-select [icon]="'/assets/icons/cor.svg'" [options]="cores" [(selectedOption)]="selectedCor" style="margin-right: 6px;"></app-regular-select>
        <app-regular-button [title]="'Criar'" (onClick)="onClickCriar()"></app-regular-button>
    </div>
</div>

<div class="modelos">
    <div class="cor" *ngFor="let slotsModelo of slots | keyvalue">
        <div class="cor-top">
            <h3>{{slotsModelo.key}}</h3>
            <a *ngIf="!existeCorPadrao()" class="tornar-cor-padrao-button" (click)="openTornarCorPadraoModal(slotsModelo.value)">Tornar padrão</a>
            <a class="delete-cor-button" (click)="openDeleteCorModal(slotsModelo.value)">Deletar cor</a>
        </div>

        <div class="container-modelos">
            
            <!-- <div class="container-modelo" (click)="goToEditorIf(slotsModelo.value.masculino2, slotsModelo.value.numDefined >= 1)">
                <div class="modelo" [class.modelo-disabled]="slotsModelo.value.numDefined < 1">
                    <h4>Masculino G, GG, EX</h4>
                    <div>
                        <img *ngIf="slotsModelo.value.numDefined >= 1" src="{{getThumbnail(slotsModelo.value.masculino2)}}">
                        <app-regular-button title="Criar" *ngIf="slotsModelo.value.numDefined < 1" [disabled]="slotsModelo.value.numDefined < 0" (click)="goToEditor(slotsModelo.value.masculino2)"></app-regular-button>
                    </div>
                </div>
            </div>
            
            <div class="container-modelo" (click)="goToEditorIf(slotsModelo.value.masculino1, slotsModelo.value.numDefined >= 2)">
                <div class="modelo" [class.modelo-disabled]="slotsModelo.value.numDefined < 2">
                    <h4>Masculino P, M</h4>
                    <div>
                        <img *ngIf="slotsModelo.value.numDefined >= 2" src="{{getThumbnail(slotsModelo.value.masculino1)}}">
                        <app-regular-button title="Criar" *ngIf="slotsModelo.value.numDefined < 2" [disabled]="slotsModelo.value.numDefined < 1" (click)="goToEditor(slotsModelo.value.masculino1)"></app-regular-button>
                    </div>
                </div>
            </div>

            <div class="container-modelo" (click)="goToEditorIf(slotsModelo.value.feminino, slotsModelo.value.numDefined >= 3)">
                <div class="modelo" [class.modelo-disabled]="slotsModelo.value.numDefined < 3">
                    <h4>Feminino P, M, G, GG</h4>
                    <div>
                        <img *ngIf="slotsModelo.value.numDefined >= 3" src="{{getThumbnail(slotsModelo.value.feminino)}}">
                        <app-regular-button title="Criar" *ngIf="slotsModelo.value.numDefined < 3" [disabled]="slotsModelo.value.numDefined < 2" (click)="goToEditor(slotsModelo.value.feminino)"></app-regular-button>
                    </div>
                </div>
            </div>

            <div class="container-modelo" (click)="goToEditorIf(slotsModelo.value.infantil2, slotsModelo.value.numDefined >= 4)">
                <div class="modelo" [class.modelo-disabled]="slotsModelo.value.numDefined < 4">
                    <h4>Infantil 08, 10, 12, 14</h4>
                    <div>
                        <img *ngIf="slotsModelo.value.numDefined >= 4" src="{{getThumbnail(slotsModelo.value.infantil2)}}">
                        <app-regular-button title="Criar" *ngIf="slotsModelo.value.numDefined < 4" [disabled]="slotsModelo.value.numDefined < 3" (click)="goToEditor(slotsModelo.value.infantil2)"></app-regular-button>
                    </div>
                </div>
            </div>

            <div class="container-modelo" (click)="goToEditorIf(slotsModelo.value.infantil1, slotsModelo.value.numDefined >= 5)">
                <div class="modelo" [class.modelo-disabled]="slotsModelo.value.numDefined < 5">
                    <h4>Infantil 02, 04, 06</h4>
                    <div>
                        <img *ngIf="slotsModelo.value.numDefined >= 5" src="{{getThumbnail(slotsModelo.value.infantil1)}}">
                        <app-regular-button title="Criar" *ngIf="slotsModelo.value.numDefined < 5" [disabled]="slotsModelo.value.numDefined < 4" (click)="goToEditor(slotsModelo.value.infantil1)"></app-regular-button>
                    </div>
                </div>
            </div> -->

            <div class="container-modelo" *ngFor="let item of slotsModelo.value.listaModelos" (click)="goToEditorIf(item.modelo, item.numDefined >= item.ordem - 1)">
                <div class="modelo" [class.modelo-disabled]="item.numDefined < item.ordem">
                    <h4>{{item.nome}}</h4>
                    <div>
                        <img *ngIf="item.numDefined >= item.ordem" src="{{getThumbnail(item.modelo)}}">
                        <app-regular-button title="Criar" *ngIf="item.numDefined < item.ordem" [disabled]="item.numDefined < item.ordem - 1" (click)="goToEditor(slotsModelo.modelo)"></app-regular-button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="numSlots === 0" class="nenhum-produto">
        Nenhum modelo definido
    </div>
</div>
<app-loading-screen></app-loading-screen>