import { ModeloService } from "../modelo.service";
import { Modelo } from "./Modelo";
import { Tamanho } from "./Tamanho";

export class SlotsModelo {
    infantil1?: Modelo;
    infantil2?: Modelo;
    masculino1?: Modelo;
    masculino2?: Modelo;
    feminino?: Modelo;
    pedal?: Modelo;
    numDefined?: number;
    listaModelos = [];

    setModelo(modelo: Modelo) {
        let ids: number[] = modelo.tamanhos.map((tamanho: Tamanho) => tamanho.id);
        
        if(ids.includes(1)) {
            this.infantil1 = modelo;
        } else if(ids.includes(4)) {
            this.infantil2 = modelo;
        } else if(ids.includes(8)) {
            this.masculino1 = modelo;
        } else if(ids.includes(10)) {
            this.masculino2 = modelo;
        } else if(ids.includes(13)) {
            this.feminino = modelo;
        } else if(ids.includes(17)) {
            this.pedal = modelo;
        }
    }

    calcNumDefined(isDefined) {
        this.numDefined = 0;

        let listModelos: any = [
            this.masculino2,
            this.masculino1,
            this.feminino,
            this.infantil2,
            this.infantil1,
            this.pedal
        ];

        listModelos.forEach(modelo => {
            if (!isDefined(modelo)) {
                return;
            }
            this.numDefined += 1;
        });

        this.setupListaModelos();
    }

    setupListaModelos() {

        if(this.masculino2) {
            this.listaModelos = [
                { 
                    modelo: this.masculino2,
                    nome: "Masculino G, GG, EX",
                    numDefined: this.numDefined,
                    ordem: 1
                },
                { 
                    modelo: this.masculino1,
                    nome: "Masculino P, M",
                    numDefined: this.numDefined,
                    ordem: 2
                },
                { 
                    modelo: this.feminino,
                    nome: "Feminino P, M, G, GG",
                    numDefined: this.numDefined,
                    ordem: 3
                },
                { 
                    modelo: this.infantil2,
                    nome: "Infantil 08, 10, 12, 14",
                    numDefined: this.numDefined,
                    ordem: 4
                },
                { 
                    modelo: this.infantil1,
                    nome: "Infantil 02, 04, 06",
                    numDefined: this.numDefined,
                    ordem: 5
                },
            ];
        } else {
            this.listaModelos = [
                { 
                    modelo: this.pedal,
                    nome: "P, M, G, GG",
                    numDefined: this.numDefined,
                    ordem: 1
                },
            ];
        }
    }
}