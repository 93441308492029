import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mudancas-modal',
  templateUrl: './mudancas-modal.component.html',
  styleUrls: ['./mudancas-modal.component.less']
})
export class MudancasModalComponent implements OnInit {

  content: any = {};

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      content: string,
      startLoadingScreen: any,
      stopLoadingScreen: any,
    },
    public dialogRef: MatDialogRef<MudancasModalComponent>,
  ) { }

  ngOnInit(): void {
    this.content = this.data.content;
  }

  close() {
    this.dialogRef.close();
  }

}
