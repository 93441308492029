import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from './generic.service';
import { Log } from './model/Log';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class LogService extends GenericService<Log> {

  constructor(requestsService: RequestsService, router: Router) {
    super('api/log', requestsService, router);
  }
}