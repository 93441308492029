import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Produto } from '../model/Produto';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-edit-produto',
  templateUrl: './edit-produto.component.html',
  styleUrls: ['./edit-produto.component.less']
})
export class EditProdutoComponent implements AfterViewInit, OnInit {

  form;
  idProduto;
  idTipoProduto;

  @ViewChild(LoadingScreenComponent) loadingScreen : LoadingScreenComponent;
  @ViewChild('produtoForm') produtoFormElement: NgForm;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder, 
    private produtoService: ProdutoService,
    private router: Router
  ) { 
    this.form = this.formBuilder.group({
      codigo: '',
      sku: '',
      sku2: '',
      sku3: '',
      sku_vtex1: '',
      sku_vtex2: '',
      sku_vtex3: '',
      height_multiplier_logo_frente: 1
    });
  }

  ngOnInit() {
    this.idProduto = this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewInit(): void {
    this.getProduto(this.idProduto);
  }

  async getProduto(id: number) {
    this.loadingScreen.startLoading();
    let produto: Produto = await this.produtoService.get(id);
    this.loadingScreen.stopLoading();

    this.form.controls['codigo'].setValue(produto.codigo);
    this.form.controls['sku'].setValue(produto.sku);
    this.form.controls['sku2'].setValue(produto.sku2);
    this.form.controls['sku3'].setValue(produto.sku3);
    this.form.controls['sku_vtex1'].setValue(produto.sku_vtex1);
    this.form.controls['sku_vtex2'].setValue(produto.sku_vtex2);
    this.form.controls['sku_vtex3'].setValue(produto.sku_vtex3);
    this.form.controls['height_multiplier_logo_frente'].setValue(produto.height_multiplier_logo_frente);

    this.idTipoProduto = produto.tipo_produto_id;
  } 

  getProdutoUrl() {
    return `/produto/${this.idProduto}`;
  }

  async onSubmit(produto: Produto) {
    produto.id = this.idProduto;
    produto.tipo_produto_id = this.idTipoProduto;

    this.loadingScreen.startLoading();
    await this.produtoService.update(produto);
    this.loadingScreen.stopLoading();

    this.router.navigate([`/produto/${produto.id}`]);
  }

  submitForm(): void {
    this.produtoFormElement.ngSubmit.emit();
  }

}
