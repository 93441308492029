import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pedido } from '../model/Pedido';
import { PedidoService } from '../pedido.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-pedido-modal',
  templateUrl: './delete-pedido-modal.component.html',
  styleUrls: ['./delete-pedido-modal.component.less']
})
export class DeletePedidoModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      pedido: Pedido,
      startLoadingScreen: any,
      stopLoadingScreen: any,
      successCallback: any,
    },
    private pedidoService: PedidoService,
    public dialogRef: MatDialogRef<DeletePedidoModalComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  async delete() {
    this.data.startLoadingScreen();
    try {
      await this.pedidoService.delete(this.data.pedido);
      await this.data.successCallback();
    } catch (e) {
      alert('Ocorreu algum erro durante a deleção deste pedido.');
    }
    this.close();
    this.data.stopLoadingScreen();
  }

}
