<app-back-button [href]="getProdutoUrl()"></app-back-button>

<div class="top">
    <h2 class="title">Editar produto</h2>
</div>

<form [formGroup]="form" #produtoForm="ngForm" (ngSubmit)="onSubmit(form.value)" class="form">

    <div>
        <app-regular-input [(form)]="form" [name]="'codigo'" [placeholder]="'Código'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <hr>

    <label>Opções</label>
    <div>
        <app-regular-input [(form)]="form" [name]="'height_multiplier_logo_frente'" [placeholder]="'Porcentagem de altura do logo na frente'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <hr>
    <label>Loja integrada</label>
    <div>
        <app-regular-input [(form)]="form" [name]="'sku'" [placeholder]="'SKU'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [name]="'sku2'" [placeholder]="'SKU2'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [name]="'sku3'" [placeholder]="'SKU3'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <hr>
    <label>VTEX</label>
    <div>
        <app-regular-input [(form)]="form" [name]="'sku_vtex1'" [placeholder]="'SKU VTEX 1'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [name]="'sku_vtex2'" [placeholder]="'SKU VTEX 2'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [name]="'sku_vtex3'" [placeholder]="'SKU VTEX 3'" [icon]="'/assets/icons/codigo.svg'"></app-regular-input>
    </div>

    <app-regular-button [title]="'Salvar'" (click)="submitForm()"></app-regular-button>
</form>
<app-loading-screen></app-loading-screen>