import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { User } from '../model/User';
import { UserService } from '../user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less']
})
export class EditUserComponent implements OnInit, AfterViewInit {

  form;
  idUser;
  permissionsData = [
    { id: 'produto_all', name: 'Produtos' },
    { id: 'pedido_all', name: 'Pedidos' },
    { id: 'user_all', name: 'Usuários' },
  ];

  @ViewChild(LoadingScreenComponent) loadingScreen : LoadingScreenComponent;
  @ViewChild('userForm') userFormElement: NgForm;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder, 
    private userService: UserService,
    private router: Router
  ) { 
    this.form = this.formBuilder.group({
      name: '',
      email: '',
      password: '',
      permissions: new FormArray([]),
    });

    this.addCheckboxes();
  }

  get permissionsFormArray() {
    return this.form.controls.permissions as FormArray;
  }

  addCheckboxes() {
    this.permissionsData.forEach(() => this.permissionsFormArray.push(new FormControl(false)));
  }

  ngOnInit(): void {
    this.idUser = this.route.snapshot.paramMap.get('id');    
  }

  ngAfterViewInit(): void {
    if (this.idUser) {
      this.getUser(this.idUser);
    }
  }

  async getUser(id: number) {    
    this.loadingScreen.startLoading();
    let user: User = await this.userService.get(id);
    this.loadingScreen.stopLoading();

    this.form.controls['name'].setValue(user.name);
    this.form.controls['email'].setValue(user.email);
    this.form.controls['password'].setValue(user.password);

    for (let column in user.permissions) {
      if (['id', 'created_at', 'updated_at', 'user_id'].includes(column)) {
        continue;
      }
      let inputIndex = this.permissionsData.findIndex(p => p.id === column);
      this.form.controls['permissions'].controls[inputIndex].setValue(user.permissions[column]);
    }
  }

  async onSubmit(user) {
    if (user.name === '' || user.email === '' || user.password === '') {
      alert(`Alerta: o usuário não possui informações válidas`);
      return;
    }

    if (user.password && user.password.length < 8) {
      alert(`Alerta: a senha deve ter ao menos 8 caracteres`);
      return;
    }

    let permissions = {};    
    user.permissions.forEach((checked, i) => {
      let permissionId = this.permissionsData[i].id;
      permissions[permissionId] = checked;
    });

    let structuredUser = Object.assign({}, user);
    structuredUser.permissions = permissions;

    this.loadingScreen.startLoading();
    try {
      if (this.idUser) {
        structuredUser.id = this.idUser;
        await this.userService.update(structuredUser);
      } else {
        await this.userService.create(structuredUser);
      }
    } catch {
      alert("Erro: Favor verificar com o suporte");
      this.loadingScreen.stopLoading();
      return;
    }
    this.loadingScreen.stopLoading();
    this.router.navigate(['/user']);
  }

  submitForm(): void {
    this.userFormElement.ngSubmit.emit();
  }

}
