<div *ngIf="form !== undefined" [formGroup]="form">
    <select class="regular-select-{{name}}" [(ngModel)]="selectedOption" (change)="onChangeValue($event.target.value)" [style.backgroundImage]="'url(' + icon + ')'" [formControlName]="name">
        <option [ngValue]="null" disabled hidden>Selecionar...</option>
        <option *ngFor="let option of options" [ngValue]="valuePath ? option[valuePath] : option">{{option.nome}}</option>
    </select>
</div>
<div *ngIf="form === undefined">
    <select class="regular-select-{{name}}" [(ngModel)]="selectedOption" (change)="onChangeValue($event.target.value)" [style.backgroundImage]="'url(' + icon + ')'">
        <option [ngValue]="null" disabled hidden>Selecionar...</option>
        <option *ngFor="let option of options" [ngValue]="valuePath ? option[valuePath] : option">{{option.nome}}</option>
    </select>
</div>