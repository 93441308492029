import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Produto } from '../model/Produto';
import { ProdutoService } from '../produto.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-produto-modal',
  templateUrl: './delete-produto-modal.component.html',
  styleUrls: ['./delete-produto-modal.component.less']
})
export class DeleteProdutoModalComponent implements OnInit {

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      produto: Produto,
      startLoadingScreen: any,
      stopLoadingScreen: any,
    },
    private produtoService: ProdutoService,
    public dialogRef: MatDialogRef<DeleteProdutoModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  async delete() {
    this.data.startLoadingScreen();
    try {
      await this.produtoService.delete(this.data.produto) as unknown as Object[];
    } catch (e) {
      alert('Ocorreu algum erro durante a deleção deste produto.');
    }
    this.dialogRef.close(true);
    this.data.stopLoadingScreen();
  }

}
