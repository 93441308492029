import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Produto } from '../model/Produto';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-novo-produto',
  templateUrl: './novo-produto.component.html',
  styleUrls: ['./novo-produto.component.less']
})
export class NovoProdutoComponent implements OnInit {

  form;

  tipos: any = [
    {
      id: 1,
      nome: "Gola padre"
    },
    {
      id: 3,
      nome: "Gola padre com bandana"
    },
    {
      id: 4,
      nome: "Camiseta pedal"
    },
    {
      id: 5,
      nome: "Capuz"
    },
    {
      id: 6,
      nome: "Novo gola careca"
    },
    {
      id: 7,
      nome: "Manga curta + gola careca"
    },
    {
      id: 8,
      nome: "Manga curta + gola padre"
    }
  ];
  selectedOption: any;

  @ViewChild('produtoForm') produtoFormElement: NgForm;

  constructor(
    private formBuilder: FormBuilder, 
    private produtoService: ProdutoService,
    private router: Router
  ) { 
    this.form = this.formBuilder.group({
      codigo: '',
      sku: '',
      tipo_produto: 1
    });
    this.selectedOption = this.tipos[0];
  }

  ngOnInit(): void {
  }

  async onSubmit(produto: Produto) {
    produto.tipo_produto_id = produto.tipo_produto.id;
    await this.produtoService.create(produto);
    this.router.navigate(['/produto']);
  }

  submitForm(): void {
    this.produtoFormElement.ngSubmit.emit();
  }

}
