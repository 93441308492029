import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Face } from '../model/Face';
import { Predefinicao } from '../model/Predefinicao';
import { PredefinicaoService } from '../predefinicao.service';
import { MatDialog } from '@angular/material/dialog';
import { DeletePredefinicaoModalComponent } from '../delete-predefinicao-modal/delete-predefinicao-modal.component';

@Component({
  selector: 'app-load-predefinicao-modal',
  templateUrl: './load-predefinicao-modal.component.html',
  styleUrls: ['./load-predefinicao-modal.component.less']
})
export class LoadPredefinicaoModalComponent implements OnInit {

  selected: Predefinicao = null;
  predefinicoes: any = null;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {
      selectedFace: Face,
      tamanhoId: number,
      loadingScreen: any
    },
    private predefinicaoService: PredefinicaoService,
    public dialogRef: MatDialogRef<LoadPredefinicaoModalComponent>,
    private dialog: MatDialog
  ) {
    
  }

  ngOnInit(): void {
    this.loadOptions();
  }

  async loadOptions() {
    this.predefinicoes = await this.predefinicaoService.getByTamanhoEFace(
      this.data.tamanhoId, this.data.selectedFace.mirror_face_id ?? this.data.selectedFace.face_id
    );
  }

  select(predefinicao) {
    if (this.selected === predefinicao) {
      this.selected = null;
    } else {
      this.selected = predefinicao;
    }
  }

  load() {
    this.data.selectedFace.aplicarPredefinicao(this.selected);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  openDeleteModal(predefinicao, e) {
    e.stopPropagation();
    const dialogRef = this.dialog.open(
      DeletePredefinicaoModalComponent,
      { 
        data: {
          predefinicao: predefinicao,
          startLoadingScreen: () => this.data.loadingScreen.startLoading(),
          stopLoadingScreen: () => this.data.loadingScreen.stopLoading(),
          successCallback: () => {
            this.loadOptions();
            this.selected = null;
          }
        }
      },
    );
  }

  async setDefault(predefinicao, e) {
    e.stopPropagation();
    this.data.loadingScreen.startLoading();
    await this.predefinicaoService.setDefault(predefinicao.id);
    await this.loadOptions();
    this.data.loadingScreen.stopLoading();
  }

}
