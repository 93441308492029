<div class="top">
    <h2 class="title">Produtos</h2>
    <app-regular-button [title]="'Novo'" (click)="onClickNovo()"></app-regular-button>
    <app-regular-input [(input)]="searchInput" (enter)="onSearch()" 
        style="margin-left: 6px;" [icon]="'/assets/icons/lupa.svg'" [placeholder]="'Pesquisar'"></app-regular-input>
    <app-regular-button [title]="'Pesquisar'" (click)="onSearch()" style="margin-left: 6px;"></app-regular-button>
    <app-regular-select
        style="margin-left: 6px;"
        [icon]="'/assets/icons/sort.svg'"
        [(selectedOption)]="orderBy"
        (onChange)="onChangeOrderBy($event)"
        [options]="optionsOrderBy"></app-regular-select>
</div>

<p class="active-search-message" [hidden]="currentSearch === ''">
    Exibindo resultados para <b>{{currentSearch}}</b> <span (click)="onClearSearch()">(limpar)</span>
</p>

<div class="produtos" *ngIf="produtoPaginacao !== null && produtoPaginacao.data.length !== 0">
    <div class="produto" *ngFor="let produto of produtoPaginacao.data">
        <div class="produto-container" (click)="onClickProduto(produto)">
            <h3>{{produto.codigo}}</h3>
            <div>
                <img src="{{getThumbnail(produto)}}">
            </div>
        </div>
    </div>
</div>

<mat-paginator
    *ngIf="produtoPaginacao !== null && produtoPaginacao.data.length !== 0"
    [pageSize]="pageSize"
    [pageIndex]="page"
    [pageSizeOptions]="[8, 16, 32, 64, 128]"
    [length]="produtoPaginacao.total"
    (page)="onPageChange($event)"
></mat-paginator>

<div class="nenhum-produto" *ngIf="produtoPaginacao !== null && produtoPaginacao.data.length === 0">
    Nenhum produto encontrado
</div>

<app-loading-screen></app-loading-screen>