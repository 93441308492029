import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigComponent } from './config/config.component';
import { EditPedidoComponent } from './edit-pedido/edit-pedido.component';
import { EditProdutoComponent } from './edit-produto/edit-produto.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { EditorComponent } from './editor/editor.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ListaLogsComponent } from './lista-logs/lista-logs.component';
import { ListaPedidosComponent } from './lista-pedidos/lista-pedidos.component';
import { ListaProdutosComponent } from './lista-produtos/lista-produtos.component';
import { ListaUserComponent } from './lista-user/lista-user.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MenuLayoutComponent } from './menu-layout/menu-layout.component';
import { NovoProdutoComponent } from './novo-produto/novo-produto.component';
import { PermissionsResolveService } from './permissions-resolve.service';
import { ProdutoComponent } from './produto/produto.component';
import { RedirectService } from './redirect.service';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    children: [],
    canActivate: [RedirectService],
  },
  {
    path: "editor/:id",
    component: EditorComponent,
    resolve: { permissions: PermissionsResolveService },
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "logout",
    component: LogoutComponent
  },
  {
    path: '',
    component: MenuLayoutComponent,
    children: [
      {
        path: "forbidden",
        component: ForbiddenComponent,
      },
      {
        path: "config",
        component: ConfigComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "log",
        component: ListaLogsComponent,
        resolve: { permissions: PermissionsResolveService },
        children: [
          {
            path: 'user/:id',
            component: ListaLogsComponent,
            resolve: { permissions: PermissionsResolveService },
          },
          {
            path: 'produto/:id',
            component: ListaLogsComponent,
            resolve: { permissions: PermissionsResolveService },
          },
          {
            path: 'pedido/:id',
            component: ListaLogsComponent,
            resolve: { permissions: PermissionsResolveService },
          },
        ]
      },
      {
        path: "user",
        component: ListaUserComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "user/novo",
        component: EditUserComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "user/edit/:id",
        component: EditUserComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "produto",
        component: ListaProdutosComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "produto/novo",
        component: NovoProdutoComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "produto/:id",
        component: ProdutoComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "produto/edit/:id",
        component: EditProdutoComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "pedido",
        component: ListaPedidosComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "pedido/novo",
        component: EditPedidoComponent,
        resolve: { permissions: PermissionsResolveService },
      },
      {
        path: "pedido/edit/:id",
        component: EditPedidoComponent,
        resolve: { permissions: PermissionsResolveService },
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
