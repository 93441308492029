import { ElementRef } from "@angular/core";
import { RequestsService } from "../requests.service";
import { Border } from "./Border";
import { Modelo } from "./Modelo";
import { Predefinicao } from "./Predefinicao";

export class Face {
    hasThumbnail: boolean = false;
    image: string;
    x: number = 100;
    y: number = 100;
    width: number = 100;
    height: number = 100;
    font_size: number = 200;
    font_color: string  = "#000000";
    font_family: string = "Airstrike";
    borders: Border[] = [
        {
            kernel_size: 1,
            color: "#000000"
        }
    ];
    thumbnail: string = 'assets/img/Frente-none.jpg';

    constructor(
        public face_id: number,
        public mirror_face_id: number
    ){}

    activateThumbnail(modelo: Modelo) {
        let faceNome = modelo.produto.tipo_produto.faces.find(f => f.id == this.face_id).nome;
        let timestamp = new Date().getTime();
        this.thumbnail = `${RequestsService.BASE}storage/produtos/${modelo.produto.codigo}/${modelo.id}/thumbnail/${faceNome}_temp.jpg?t=${timestamp}`
    }

    deactivateThumbnail() {
        this.thumbnail = "assets/img/Frente-none.jpg";
    }

    aplicarPredefinicao(predefinicao: Predefinicao) {
        this.x = predefinicao.valores.x;
        this.y = predefinicao.valores.y;
        this.width = predefinicao.valores.width;
        this.height = predefinicao.valores.height;
        this.font_family = predefinicao.valores.font_family;
        this.font_color = predefinicao.valores.font_color;
        this.font_size = predefinicao.valores.font_size;
        this.borders = predefinicao.valores.borders;
    }

}