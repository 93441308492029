import { Router } from '@angular/router';
import { GenericModel } from './model/GenericModel';
import { RequestsService } from './requests.service';

export abstract class GenericService<T extends GenericModel> {

  constructor(protected url: string, protected requestsService: RequestsService, protected router: Router) {
    
    if(this.url[0] == '/') {
      this.url = this.url.slice(0);
    }

    if(this.url[this.url.length - 1] == '/') {
      this.url = this.url.slice(this.url.length - 1);
    }

  }

  async get(id: number): Promise<T> {
    try {
      return await this.requestsService.get(`${this.url}/${id}`).toPromise() as unknown as T;
    } catch (e) {
      if(e.status == 401) {
        this.router.navigate(["/login"]);
      } else {
        throw e;
      }

      return null;
    }
  }

  async getAll(search?: string, page?: number, pageSize?: number, orderBy?: string, other?: any): Promise<T[]> {
    let url = this.url + "?";

    if(search) {
      url += `search=${search}&`;
    }

    if(page != null && pageSize != null) {
      url += `page=${page}&pageSize=${pageSize}&`;
    }

    if(orderBy != null) {
      url += `orderBy=${orderBy}&`;
    }

    if(other != null && typeof other == "object") {
      for(let key in other) {
        url += `${key}=${other[key]}&`;
      }
    }

    try {
      return await this.requestsService.get(url).toPromise() as unknown as T[];
    } catch (e) {
      if(e.status == 401) {
        this.router.navigate(["/login"]);
      } else {
        throw e;
      }

      return null;
    }

  }

  async create(element: T): Promise<T> {
    return await this.requestsService.post(this.url, element).toPromise() as T;
  }

  async update(element: T): Promise<T> {
    return await this.requestsService.put(`${this.url}/${element.id}`, element).toPromise() as T;
  }

  async delete(element: T) {
    return await this.requestsService.delete(`${this.url}/${element.id}`).toPromise();
  }

  async post(route, data) {
    return await this.requestsService.post(`${this.url}/${route}`, data).toPromise();
  }

}
