<div #container id="container">
    <div #content id="content">
        <img [src]="img" #imgElement/>
        <div [style.display]="faceTemCustomizacao() ? 'block' : 'none'" class="text" id="text" (mouseenter)="onMouseHover(true)" (mouseleave)="onMouseHover(false)"
            [style.left.%]="getLeft()"
            [style.top.%]="getTop()"
            [style.width.%]="getWidth()"
            [style.height.%]="getHeight()"
        ></div>

        <app-regua 
            (contextmenu)="onContextMenu(regua, $event)"
            *ngFor="let regua of reguas"
            [(left)]="regua.left"
            [show]="regua.faceId == face?.face_id" 
            (mouseenter)="onMouseHover(true)" (mouseleave)="onMouseHover(false)"
            [currentZoomScale]="currentZoomScale"
            ></app-regua>
    </div>
</div>

<mat-menu #contextMenu="matMenu">
    <button mat-menu-item (click)="centerInCanvas()">
        <mat-icon>align_horizontal_center</mat-icon>
        <span>Centralizar régua</span>
    </button>
</mat-menu>

<div style="position: fixed;"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu">
</div>
