<div class="top">
    <h2 class="title">Configurações</h2>
</div>

<form [formGroup]="form" #configForm="ngForm" (ngSubmit)="onSubmit(form.value)" class="form">

    <section>
        <h2 class="subtitle">Deslocamento vertical do texto na face das costas para camisetas com logotipo</h2>
        <h3>PESCA</h3>
        <div *ngFor="let tamanho of tamanhosPesca">
            <label>
                <span>{{ tamanho.nome }} (px)</span>
                <app-regular-input [(form)]="this.form.controls.offsetLogoY" [name]="tamanho.id"
                        [type]="'number'"
                        [icon]="'/assets/icons/height.svg'">
                    </app-regular-input>
            </label>
        </div>
        <h3 style="margin-top: 15px">
            PEDAL <i>(atualmente não ativas na integração com a VTEX)</i>
        </h3>
        <div *ngFor="let tamanho of tamanhosPedal">
            <label>
                <span>{{ tamanho.nome }} (px)</span>
                <app-regular-input [(form)]="this.form.controls.offsetLogoY" [name]="tamanho.id"
                        [type]="'number'"
                        [icon]="'/assets/icons/height.svg'">
                    </app-regular-input>
            </label>
        </div>
    </section>

    <app-regular-button [title]="'Salvar'" (click)="submitForm()"></app-regular-button>
</form>

<section class="fonts-section">
    <h2 style="font-weight: 500">Gerenciar fontes customizadas</h2>
    <ul>
        <li *ngFor="let fonte of fontes">
            <span>{{ fonte.nome }}</span>
            <a class="delete-button">Deletar</a>
        </li>
        <span class="empty-font-list" *ngIf="fontes.length === 0">
            Nenhuma fonte customizada cadastrada
        </span>
    </ul>

    <form [formGroup]="fonteForm" #addFonteForm="ngForm" (ngSubmit)="onAddFonte()">
        <h3>Nova fonte</h3>
        <p>
            Atenção: cadastre apenas um estilo de fonte por vez, e não a família inteira.
            Por exemplo, se for necessário mais do que um estilo da fonte "Arial",
            cadastre "Arial Regular", depois "Arial Bold", e assim por diante.
        </p>
        <label>
            <span>Nome</span>
            <app-regular-input
                [icon]="'/assets/icons/codigo.svg'"
                [(form)]="fonteForm"
                [name]="'nome'"
                [placeholder]="'Arial Regular'"
            ></app-regular-input>
        </label>
        <label style="margin-bottom: 12px">
            <span>Arquivo (extensão .ttf ou .otf)</span>
            <input
                type="file"
                id="font-file"
                style="display: none"
                (change)="onSelectFonte($event.target, $event.target.files)"
                accept="application/x-font-ttf,application/x-font-otf"
            />
            <label class="select-file" for="font-file">Selecionar arquivo...</label>
        </label>

        <app-regular-button
            [title]="'Adicionar'"
            (click)="submitFonteForm()"
        ></app-regular-button>
    </form>
</section>


<app-loading-screen></app-loading-screen>