import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from './generic.service';
import { User } from './model/User';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GenericService<User> {

  constructor(requestsService: RequestsService, router: Router) { 
    super('api/user', requestsService, router);
  }

  async register(
    name: string,
    email: string, 
    password: string, 
    permissaoProdutoAll: boolean, 
    permissaoPedidoAll: boolean,
    permissaoUserAll: boolean,
  ) {
    return this.requestsService.post("/register", {
      name: name,
      email: email,
      password: password,
      permissions: {
        produto_all: permissaoProdutoAll,
        pedido_all: permissaoPedidoAll,
        user_all: permissaoUserAll,
      }
    }).toPromise();
  }
}
