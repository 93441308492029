import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from './generic.service';
import { Tamanho } from './model/Tamanho';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class TamanhoService extends GenericService<Tamanho> {

  constructor(requestsService: RequestsService, router: Router) {
    super('api/tamanho', requestsService, router);
  }
  
}