import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListaProdutosComponent } from './lista-produtos/lista-produtos.component';
import { HttpClientModule } from '@angular/common/http';
import { RegularButtonComponent } from './regular-button/regular-button.component';
import { RegularInputComponent } from './regular-input/regular-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProdutoComponent } from './produto/produto.component';
import { RegularSelectComponent } from './regular-select/regular-select.component';
import { NovoProdutoComponent } from './novo-produto/novo-produto.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { EditorComponent } from './editor/editor.component';
import { MenuLayoutComponent } from './menu-layout/menu-layout.component';
import { ListaPedidosComponent } from './lista-pedidos/lista-pedidos.component';
import { FaceInputComponent } from './face-input/face-input.component';
import { ColorInputComponent } from './color-input/color-input.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CanvasComponent } from './canvas/canvas.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SavePredefinicaoModalComponent } from './save-predefinicao-modal/save-predefinicao-modal.component';
import { LoadPredefinicaoModalComponent } from './load-predefinicao-modal/load-predefinicao-modal.component';
import { EditPedidoComponent } from './edit-pedido/edit-pedido.component';
import { ListaItensComponent } from './lista-itens/lista-itens.component';
import { DeleteProdutoModalComponent } from './delete-produto-modal/delete-produto-modal.component';
import { DeletePedidoModalComponent } from './delete-pedido-modal/delete-pedido-modal.component';
import { DeletePredefinicaoModalComponent } from './delete-predefinicao-modal/delete-predefinicao-modal.component';
import { LoginComponent } from './login/login.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { LogoutComponent } from './logout/logout.component';
import { EditProdutoComponent } from './edit-produto/edit-produto.component';
import { ReguaComponent } from './regua/regua.component';
import { DeleteCorModalComponent } from './delete-cor-modal/delete-cor-modal.component';
import { DuplicateProdutoModalComponent } from './duplicate-produto-modal/duplicate-produto-modal.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ListaUserComponent } from './lista-user/lista-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { DeleteUserModalComponent } from './delete-user-modal/delete-user-modal.component';
import { ListaLogsComponent } from './lista-logs/lista-logs.component';
import { MudancasModalComponent } from './mudancas-modal/mudancas-modal.component';
import { ConfigComponent } from './config/config.component';
import { TornarCorPadraoModalComponent } from './tornar-cor-padrao-modal/tornar-cor-padrao-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    ListaProdutosComponent,
    RegularButtonComponent,
    ProdutoComponent,
    RegularSelectComponent,
    RegularInputComponent,
    NovoProdutoComponent,
    BackButtonComponent,
    EditorComponent,
    MenuLayoutComponent,
    ListaPedidosComponent,
    FaceInputComponent,
    ColorInputComponent,
    CanvasComponent,
    LoadingScreenComponent,
    SavePredefinicaoModalComponent,
    LoadPredefinicaoModalComponent,
    EditPedidoComponent,
    ListaItensComponent,
    DeleteProdutoModalComponent,
    DeletePedidoModalComponent,
    DeletePredefinicaoModalComponent,
    LoginComponent,
    ErrorModalComponent,
    LogoutComponent,
    EditProdutoComponent,
    ReguaComponent,
    DeleteCorModalComponent,
    DuplicateProdutoModalComponent,
    ForbiddenComponent,
    ListaUserComponent,
    EditUserComponent,
    DeleteUserModalComponent,
    ListaLogsComponent,
    MudancasModalComponent,
    ConfigComponent,
    TornarCorPadraoModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [SavePredefinicaoModalComponent]
})
export class AppModule { }
