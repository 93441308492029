import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  // static BASE = "http://localhost:8080/";
  static BASE = "https://king.dimo.com.br/";

  constructor(private http: HttpClient) { }

  get(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean}) {

    url = RequestsService.BASE + url;
    
    if(!options) {
      options = {} as any;
    }
    options.headers = new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`});

    return this.http.get(url, options);
  }

  post(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    url = RequestsService.BASE + url;
    
    if(!options) {
      options = {} as any;
    }
    options.headers = new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`});

    return this.http.post(url, body, options);
  }

  put(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<Object> {

    url = RequestsService.BASE + url;

    if(!options) {
      options = {} as any;
    }
    options.headers = new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`});

    return this.http.put(url, body, options);
  }

  delete(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }) {
    url = RequestsService.BASE + url;

    if(!options) {
      options = {} as any;
    }
    options.headers = new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`});

    return this.http.delete(url, options);
  }

}
