<div class="field">
  <div class="file">
    <label class="file-label">
      <input #imageInput id="imageInput" class="file-input" type="file" accept="image/*" (change)="uploadImage($event)" multiple />
      <span class="file-cta">
        <span class="file-icon">
          <img src="/assets/icons/upload.svg" />
        </span>
        <span class="file-label" *ngIf="!multiple">
          Escolher imagem...
        </span>
        <span class="file-label" *ngIf="multiple">
          Escolher várias imagens...
        </span>
      </span>
    </label>
  </div>
</div>