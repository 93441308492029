import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  form;
  errorMessage: string = "";
  showErrorMessage: boolean = false;

  @ViewChild('loginForm') loginFormElement: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      email: '',
      password: ''
    });
  }

  ngOnInit(): void {
  }

  async onSubmit(userCredentials) {
    try {
      if (await this.authService.login(userCredentials.email, userCredentials.password)) {
        this.router.navigate([`/`]);
      }
    } catch (ex){
      this.errorMessage = ex.error.message;
      this.showErrorMessage = true;
      setTimeout(() => {
        this.showErrorMessage = false;
      }, 3500);
    }
  }

  submitForm(): void {
    this.loginFormElement.ngSubmit.emit();
  }

}
