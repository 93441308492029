<div class="top">
    <h2 class="title">Logs</h2>
</div>

<p *ngIf="resourceName !== ''">
    Logs do {{ resourceDesc(logType) }} <b style="font-weight: 500">{{ resourceName }}</b>
</p>

<table class="logs" *ngIf="logPaginacao !== null && logPaginacao.data.length !== 0">
    <tr>
        <th style="width: 10%">Data</th>
        <th style="width: 10%">Usuário</th>
        <th style="width: 4%">Ação</th>
        <th style="width: 8%">Tipo registro</th>
        <th style="width: 6%">ID registro</th>
        <th style="width: 20%">Descrição registro</th>
        <th style="width: 10%">Extra</th>
        <th style="width: 2%">Mudanças</th>
    </tr>
    <tr class="log" *ngFor="let log of logPaginacao.data; let i = index;" [style.backgroundColor]="i % 2 ? '#f0f0f0' : 'white'">
        <td>{{log.datetime}}</td>
        <td>{{log.user}}</td>
        <td [style.color]="{'created': 'green', 'updated': 'blue', 'deleted': 'red'}[log.action]">{{action(log.action)}}</td>
        <td>{{log.subject_type}}</td>
        <td>{{log.subject_id}}</td>
        <td>{{log.subject_description}}</td>
        <td>{{log.extra}}</td>
        <td><span (click)="openMudancasModal(log.changes)">Detalhar</span></td>
    </tr>
</table>

<mat-paginator
    *ngIf="logPaginacao !== null && logPaginacao.data.length !== 0"
    [pageSize]="pageSize"
    [pageIndex]="page"
    [pageSizeOptions]="[20, 40, 80]"
    [length]="logPaginacao.total"
    (page)="onPageChange($event)"
></mat-paginator>

<app-loading-screen></app-loading-screen>
