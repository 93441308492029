import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.less']
})
export class ColorInputComponent implements OnInit {

  @Input('input') input;
  @Output('inputChange') inputChange: EventEmitter<String> = new EventEmitter<String>();
  icon: String = '/assets/icons/cor.svg';

  constructor() { }

  ngOnInit(): void {
  }

  onChangeInput(event) {
    this.input = event;
    this.inputChange.emit(this.input);
  }

}
