import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) { }

  async updateOffsetLogoY(offsetLogoYValues) {
    return this.http.post(
      `${RequestsService.BASE}api/config/setOffsetLogoY`,
      offsetLogoYValues,
      {
        headers: new HttpHeaders()
          .append("Content-Type", "application/json")
          .append("Authorization", `Bearer ${AuthService.getUserSession()}`)
      }
    ).toPromise() as unknown as any;
  }
}
