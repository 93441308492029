import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { GenericService } from './generic.service';
import { Fonte } from './model/Fonte';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class FonteService extends GenericService<Fonte> {

  constructor(requestsService: RequestsService, router: Router, private http: HttpClient) {
    super('api/fonte', requestsService, router);
  }

  async uploadFonte(nome: string, arquivo: File): Promise<any> {
    const formData: FormData = new FormData();

    formData.append('nome', nome);
    formData.append('arquivo', arquivo, arquivo.name);

    return new Promise((resolve, reject) => {
      this.http
        .post(`${RequestsService.BASE}api/fonte/upload`, formData, {
          headers: new HttpHeaders()
            .append("Authorization", `Bearer ${AuthService.getUserSession()}`)
        }).subscribe(resolve, reject);
    });
  }
  
}
