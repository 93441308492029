import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PedidoService } from '../pedido.service';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { Pedido } from '../model/Pedido';
import { IntegracaoLojaIntegradaService } from '../integracao-loja-integrada.service';

@Component({
  selector: 'app-edit-pedido',
  templateUrl: './edit-pedido.component.html',
  styleUrls: ['./edit-pedido.component.less']
})
export class EditPedidoComponent implements OnInit {

  form;
  itens = [];
  idPedido;
  pedido: Pedido;

  @ViewChild(LoadingScreenComponent) loadingScreen : LoadingScreenComponent;
  @ViewChild('pedidoForm') produtoFormElement: NgForm;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder, 
    private pedidoService: PedidoService,
    private integracaoLojaIntegradaService: IntegracaoLojaIntegradaService,
    private router: Router
  ) { 
    this.form = this.formBuilder.group({
      codigo: '',
      generate_at_night: true,
      gerar_com_etiqueta_uv: false,
    });
  }

  ngOnInit(): void {
    this.idPedido = this.route.snapshot.paramMap.get('id');
    this.getPedido = this.getPedido.bind(this);
  }

  async getPedido(id: number) {    
    this.loadingScreen.startLoading();
    this.pedido = await this.pedidoService.get(id);
    this.loadingScreen.stopLoading();

    this.form.controls['codigo'].setValue(this.pedido.codigo);
    this.form.controls['gerar_com_etiqueta_uv'].setValue(this.pedido.gerar_com_etiqueta_uv);
    this.itens = this.pedido.itens.map(item => ({
      numero: item.numero,
      produto: item.produto,
      cor: item.cor,
      tamanho: item.tamanho,
      frente: item.frente,
      costas: item.costas,
      produto_sku: item.produto_sku,
      imagem_url: item.imagem_url,
      produto_url: item.produto_url,
      tem_logo: item.tem_logo,
      logo_url: item.logo_url,
      logo_url_original: item.logo_url_original,
    }));
  }

  async onSubmit(pedido) {
    if (pedido.codigo === '') {
      alert(`Alerta: o pedido não possui um código válido`);
      return;
    }

    for (let item of this.itens) {
      let incompleteData = [];
      for (let property of ['cor', 'tamanho']) {
        if (item[property] === null || item[property] === '' || item[property] === undefined) {
          incompleteData.push(property);
        }
      }

      if (incompleteData.length > 0) {
        let capitalize = (s) => s[0].toUpperCase() + s.slice(1);
        alert(`Alerta: o item ${item.numero} possui dados incompletos: ${incompleteData.map(capitalize).join(', ')}`);
        return;
      }
    }
    
    pedido.itens = this.itens.map(o => ({
      produto_id: o.produto?.id,
      cor_id: o.cor?.id,
      tamanho_id: o.tamanho?.id,
      frente: o.frente,
      costas: o.costas,
      produto_sku: o.produto_sku,
      imagem_url: o.imagem_url,
      produto_url: o.produto_url,
      tem_logo: o.tem_logo,
      logo_url: o.logo_url,
      logo_url_original: o.logo_url_original
    }));
    
    this.loadingScreen.startLoading();
    try {
      if (this.idPedido) {
        pedido.id = this.idPedido;
        await this.pedidoService.update(pedido);
      } else {
        await this.pedidoService.gerar(pedido);
      }
    } catch {
      alert("Erro: Favor verificar com o suporte");
      this.loadingScreen.stopLoading();
      return;
    }
    this.loadingScreen.stopLoading();
    this.router.navigate(['/pedido']);
  }

  submitForm(): void {
    this.produtoFormElement.ngSubmit.emit();
  }

  async reintegrar() {
    if (!confirm(`Deseja realmente reintegrar o pedido ${this.pedido.codigo}? Todos os dados editados serão perdidos`)) {
      return;
    }

    this.loadingScreen.startLoading();
    try {
      await this.integracaoLojaIntegradaService.reintegrarPedido(this.pedido);
    } catch (e) {
      
    }
    this.loadingScreen.stopLoading();
    this.router.navigate(['/pedido']);
  }

}
