import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) {}

  async upload(file: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return new Promise((resolve, reject) => {
      this.http
        .post(`${RequestsService.BASE}api/media`, formData, {
          headers: new HttpHeaders()
            .append("Authorization", `Bearer ${AuthService.getUserSession()}`)
        }).subscribe(resolve, reject);
    });
  }
}
