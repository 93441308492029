import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.less']
})
export class BackButtonComponent implements OnInit {

  @Input("href") href: string = "#";

  constructor() { }

  ngOnInit(): void {
  }

}
