import { Injectable } from '@angular/core';
import { RequestsService } from './requests.service';
import { saveAs } from "file-saver";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadPedidosService {

  constructor(private http: HttpClient) {}

  async download(ids: number[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${RequestsService.BASE}api/pedido/download`, {pedidosIds: ids}, {
        responseType: "text",
        headers: new HttpHeaders()
          .append("Content-Type", "application/json")
          .append("Authorization", `Bearer ${AuthService.getUserSession()}`)
      }).subscribe(
        url => {
          var link = document.createElement('a');
          link.href = url;
          link.download = "";
          link.click();
          resolve(true);
        },
        error => {
          reject(error);
        }
      );
    });
    
  }

}
