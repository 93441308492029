import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Pedido } from './model/Pedido';
import { RequestsService } from './requests.service';

@Injectable({
  providedIn: 'root'
})
export class IntegracaoLojaIntegradaService {

  constructor(private http: HttpClient) {}

  async integrar(): Promise<any> {
    await this.http.get(`${RequestsService.BASE}api/integracao`, {
      headers: new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`}),
    }).toPromise();
  }

  async reintegrarPedido(pedido: Pedido): Promise<any> {
    await this.http.post(`${RequestsService.BASE}api/reintegrarPedido`, { codigoPedido: pedido.codigo }, {
      headers: new HttpHeaders({'Authorization': `Bearer ${AuthService.getUserSession()}`}),
    }).toPromise();
  }
}
