<app-back-button [href]="'/user'"></app-back-button>

<div class="top">
    <h2 class="title">{{ idUser ? 'Editar' : 'Novo' }} usuário</h2>
</div>

<form [formGroup]="form" #userForm="ngForm" (ngSubmit)="onSubmit(form.value)" class="form">

    <div>
        <app-regular-input [(form)]="form" [name]="'name'" [placeholder]="'Nome'" [icon]="'/assets/icons/user.svg'"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [name]="'email'" [placeholder]="'E-mail'" [icon]="'/assets/icons/email.svg'" [readonly]="!!idUser"></app-regular-input>
    </div>

    <div>
        <app-regular-input [(form)]="form" [type]="'password'" [name]="'password'" [placeholder]="idUser ? 'Nova senha' : 'Senha'" [icon]="'/assets/icons/chave.svg'"></app-regular-input>
    </div>

    <div class="permissions">
        <p>Permissões</p>
        <label formArrayName="permissions" *ngFor="let permission of permissionsFormArray.controls; let i = index">
            <input type="checkbox" [formControlName]="i">
            {{permissionsData[i].name}}
        </label>
    </div>
    

    <app-regular-button [title]="idUser ? 'Salvar' : 'Criar'" (click)="submitForm()"></app-regular-button>
</form>
<app-loading-screen></app-loading-screen>