import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenComponent } from '../loading-screen/loading-screen.component';
import { CanvasComponent } from '../canvas/canvas.component';
import { Border } from '../model/Border';
import { Face } from '../model/Face';
import { Modelo } from '../model/Modelo';
import { ModeloService } from '../modelo.service';
import { FonteService } from '../fonte.service';
import { PredefinicaoService } from '../predefinicao.service';
import { SavePredefinicaoModalComponent } from '../save-predefinicao-modal/save-predefinicao-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadPredefinicaoModalComponent } from '../load-predefinicao-modal/load-predefinicao-modal.component';
import { Predefinicao } from '../model/Predefinicao';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.less']
})
export class EditorComponent implements AfterViewInit {

  @ViewChild(LoadingScreenComponent) loadingScreen : LoadingScreenComponent;
  @ViewChild(CanvasComponent) canvas : CanvasComponent;

  faces: Map<string, Face> = new Map();

  fonts = [
    {
      nome: "Airstrike"
    }
  ]

  selectedFace: Face;
  modelo: Modelo;

  constructor(
    private router: Router, 
    private modeloService: ModeloService,
    private fonteService: FonteService,
    private route: ActivatedRoute,
    private predefinicaoService: PredefinicaoService,
    private dialog: MatDialog
  ) {
    
    if(router.getCurrentNavigation().extras.state) {
      this.modelo = router.getCurrentNavigation().extras.state.modelo;
    }

  }

  ngAfterViewInit() {
    this.getFontes();
    
    if(!this.modelo) {
      this.getModelo();
    }
  }

  selectFace(face: string) {
    for(var f of this.modelo.produto.tipo_produto.faces) {
      var ref: ElementRef = new ElementRef(document.getElementById("face" + f.id));
      
      if(face === f.nome) {
        ref.nativeElement.classList.add('selected');
      } else {
        ref.nativeElement.classList.remove('selected');
      }
    }
    
    this.selectedFace = this.faces.get(face);
  }

  centerHorizontally() {
    this.canvas.centerHorizontally();
  }

  removeBorder(border) {
    let index = this.selectedFace.borders.indexOf(border);
    if(index > -1) {
      this.selectedFace.borders.splice(index, 1);
    } 
  }

  addBorder() {
    this.selectedFace.borders.push({
      kernel_size: 1,
      color: "#000000"
    });
  }

  async salvar() {
    let data = {
      id: this.modelo.id,
      produto_id: this.modelo.produto_id,
      cor_id: this.modelo.cor_id,
      tamanhos_id: this.modelo.tamanhos_id,
      blueprints: this.modelo.produto.tipo_produto.faces.map(a => this.faces.get(a.nome)),
    }

    this.loadingScreen.startLoading();
    await this.modeloService.saveModelo(data);
    this.loadingScreen.stopLoading();
  }

  async getModelo() {
    this.loadingScreen.startLoading();
    this.modelo = await this.modeloService.get(parseInt(this.route.snapshot.paramMap.get('id')));
    await this.modeloService.resetFaces(this.modelo);
    
    for(var f of this.modelo.produto.tipo_produto.faces) {
      var nomeFace = f.nome as string;
      this.faces.set(nomeFace, new Face(f.id, f.mirror_face_id));
    }

    this.selectFace(this.modelo.produto.tipo_produto.faces[0].nome);

    this.modelo.produto.tipo_produto.faces.forEach(a => this.faces.get(a.nome).activateThumbnail(this.modelo))

    if (this.modelo.blueprints.length === 0) {
      let arrayFaces = Array.from(this.faces);
      for (let [_, face] of arrayFaces) {        
        let predefinicoes = await this.predefinicaoService.getByTamanhoEFace(
          this.modelo.tamanhos[0].id, face.mirror_face_id ?? face.face_id
        );

        let predefinicaoDefault = predefinicoes.find((predefinicao: Predefinicao) => predefinicao.is_default);
        if (predefinicaoDefault) {
          face.aplicarPredefinicao(predefinicaoDefault);
        }
      }
    }

    this.loadingScreen.stopLoading();

    for(var blueprint of this.modelo.blueprints) {
      var arrayFaces = Array.from(this.faces); 
      var face: Face = arrayFaces.find((face: any) => face[1].face_id == blueprint.face_id)[1] as Face;
      face.x = blueprint.x;
      face.y = blueprint.y;
      face.width = blueprint.blueprint.components[0].size.width;
      face.height = blueprint.blueprint.components[0].size.height;
      face.font_family = blueprint.blueprint.components[0].font_family;
      face.font_size = blueprint.blueprint.components[0].font_size;
      face.font_color = blueprint.blueprint.components[0].color;
      face.borders = blueprint.post_processing.map((processing) => { 
        return {kernel_size: processing.kernel_size, color: processing.color};
      }) as Border[];
    }

  }

  async getFontes() {
    this.fonts = await this.fonteService.getAll();
  }

  getTamanhos() {
    if(!this.modelo) {
      return "";
    }

    var frase = "";

    this.modelo.tamanhos.forEach((tamanho, index) => {
      frase += tamanho.nome;

      if(index != this.modelo.tamanhos.length-1) {
        frase += ", ";
      }
    });

    return frase;
  }

  getThumbnail(face: Face) {
    if(!face) {
      return "assets/img/Frente-none.jpg";
    }

    return face.thumbnail;
  }

  onErrorThumbnail(face: Face) {
    if(face) {
      face.deactivateThumbnail();
    }
  }

  getFaceIdFromFace(face){
    return this.modelo.produto.tipo_produto.faces.find(a => a.nome == face).id;
  }

  onUploadImageInit(face) {
    this.loadingScreen.startLoading();
  }

  onUploadImageFinish(face: Face) {
    this.loadingScreen.stopLoading();
    // Atualiza todas as faces pq agora o input de imagem pega todas faces de uma vez
    this.faces.forEach((face) => face.activateThumbnail(this.modelo));
  }

  loadPredefinicao() {
    const dialogRef = this.dialog.open(
      LoadPredefinicaoModalComponent,
      { 
        data: {
          selectedFace: this.selectedFace,
          tamanhoId: this.modelo.tamanhos[0].id,
          loadingScreen: this.loadingScreen
        }
      },
    );
  }

  savePredefinicao() {
    const dialogRef = this.dialog.open(
      SavePredefinicaoModalComponent,
      { 
        data: {
          selectedFace: this.selectedFace,
          tamanhoId: this.modelo.tamanhos[0].id
        }
      },
    );
  }

  faceTemCustomizacao() {
    if (this.modelo === undefined || this.selectedFace === undefined) {
      return false;
    }

    const facesSemCustomizacao = ['Bandana', 'Capuz']
    const nomeFaceAtual = this.modelo.produto.tipo_produto.faces.find(f => f.id == this.selectedFace.face_id).nome;

    return !facesSemCustomizacao.includes(nomeFaceAtual);
  }

}
